import type { DefineMethods } from 'aspida'
import type { Member } from '~/types/member'
import type { SearchLinkedMemberParams } from '~/types/searchLinkedMemberParams'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    query: SearchLinkedMemberParams
    resBody: {
      count: number
      members: Member[]
      unlinked_slack_emails: []
    }
  }
}>

const mockCommonData = {
  code: '200',
  firstname_kana: '',
  lastname_kana: '',
  employee_code: '',
  employing_type: 1,
  working_status: 1,
  date_resigned: '2024/03/27',
  resign_reason: 'aaaaa',
  resign_reason_text: 'aaaaa',
  gender: 1,
  birth_year: 1991,
  birth_month: 8,
  birth_day: 10,
  mobile_tel_number: '09012345678',
  delivery_date: '',
  profession: '',
  senior_id: null,
  initial_evaluation: '',
  is_newbie: 1,
  entry_reason: '',
  recruiter: '',
  initial_evaluated_point: '',
  initial_worry_point: '',
  route_employed: '',
  route_employed_detail: '',
  job_change_count: null,
  working_years: null,
  graduation_type: null,
  ex_company: '',
  ex_profession: '',
  other_info: '',
  other_info2: '',
  other_info3: '',
  is_stopped_send_anniv_mail: 1,
  is_stopped_send_questions_mail: 0,
  mail_language: 0,
  anniv_answer_completed: 1,
  anniv_senior_answer_completed: 1,
  anniv_answered_senior_id: null,
  anniv_sent: 1,
  answer_text: '',
  answered_at: null,
  condition_type: 1 as const,
  date_contracted: '',
  department: {
    id: 1,
    parent_id: 1,
    name: '',
    department_manager_id: 1,
    department_name: '',
    short_name: '',
    full_path: '',
  },
  image_uri: null,
  line_works_send_success_flag: 1,
  line_works_id: 1,
  line_user_id: 1,
  sms_status: 0 as const,
  using_slack: 0 as const,
  app_status: 0 as const,
  answers: [
    {
      id: 1,
      month: '',
      condition_type: 1 as const,
    },
  ],
  has_answer: false,
  importance: 1,
  interview_status: 0,
  interview_due_date: '',
  hr_user_id: null,
  talent_status: 0 as const,
  message_read: 0 as const,
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      count: 1,
      members: [
        {
          ...mockCommonData,
          ...{
            id: 1,
            company_id: 100,
            firstname: '理沙',
            lastname: '高橋',
            line_status: 0 as const,
            access_key: '10835585',
            slack_name: 'r.takahashi',
            email: 'r.takahashi@example.com',
          },
        },
        {
          ...mockCommonData,
          ...{
            id: 2,
            company_id: 100,
            firstname: '裕美',
            lastname: '宮崎',
            line_status: 1,
            access_key: '50835589',
            slack_name: 'y.miyazaki',
            email: 'y.miyazaki@example.com',
          },
        },
      ],
      unlinked_slack_emails: [],
    },
  }),
})
