import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { DepartmentSearchItem } from '~/types/department'

export type Methods = DefineMethods<{
  get: {
    query?: {
      department_name?: string
      department_manager_name?: string
      with_trashed?: number
    }
    resBody: {
      result: {
        departments: DepartmentSearchItem[]
      }
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        departments: [
          {
            id: 1,
            department_name: 'デモ株式会社',
            name: 'デモ株式会社',
            department_manager_name: null,
            is_archived: false,
            is_blank: false,
            parent_id: null,
            parent_name: '',
            member_count: 10,
            has_descendants: true,
            parent_departments: [],
            enable_archive: false,
          },
          {
            id: 2,
            name: '営業部',
            department_name: 'デモ株式会社 > 営業部',
            department_manager_name: '田中 太郎',
            is_archived: true,
            is_blank: false,
            parent_id: 1,
            parent_name: 'デモ株式会社',
            member_count: 1,
            has_descendants: false,
            parent_departments: [{ id: 1, name: 'デモ株式会社' }],
            enable_archive: false,
          },
          {
            id: 3,
            name: '管理部',
            department_name: 'デモ株式会社 > 管理部',
            department_manager_name: null,
            is_archived: false,
            is_blank: false,
            parent_id: 1,
            parent_name: 'デモ株式会社',
            member_count: 1,
            has_descendants: false,
            parent_departments: [{ id: 1, name: 'デモ株式会社' }],
            enable_archive: true,
          },
        ],
      },
    },
  }),
})
