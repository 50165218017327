import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MemberSchedule } from '~/types/memberSchedule'
import useDate from '~/composables/useDate'
const { dateFormat } = useDate()

export type Methods = DefineMethods<{
  get: {
    resBody: MemberSchedule[]
  }
}>

const today = new Date()
const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)

const addMonths = (date: Date, months: number) : string => {
  const result = new Date(date)
  result.setMonth(result.getMonth() + months)
  const year = result.getFullYear()
  const month = String(result.getMonth() + 1).padStart(2, '0')
  const day = String(result.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}

const data = [
      {
        date: dateFormat(addMonths(startOfMonth, 1), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 1,
        answer_status: 1,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 2), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 2,
        answer_status: 2,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 3), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 3,
        answer_status: 3,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 4), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 4,
        answer_status: 4,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 5), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 5,
        answer_status: 1,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 6), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 6,
        answer_status: 2,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 7), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 7,
        answer_status: 3,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 8), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 8,
        answer_status: 7,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 9), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 9,
        answer_status: 8,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 10), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 10,
        answer_status: 8,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 11), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 11,
        answer_status: 8,
      },
      {
        date: dateFormat(addMonths(startOfMonth, 12), 'yyyy-MM-dd'),
        enq_type: 1,
        enq_round: 12,
        answer_status: 8,
      },
      {
        date: dateFormat(addMonths(today, 12), 'yyyy-MM-dd'),
        enq_type: 2,
        enq_round: 1,
        answer_status: 5,
      },
    ]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: data,
  }),
})
