import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { CustomMonthlyQuestion } from '~/types/customMonthlyQuestion'

export type Methods = DefineMethods<{
  get: {
    resBody: CustomMonthlyQuestion[]
  }
  post: {
    reqBody: CustomMonthlyQuestion
    resBody: CustomMonthlyQuestion[]
  }
}>

const questions = [
  {
    id: 1,
    seqno: 1,
    question_text: '直近の労働勤務時間はあなたにとって多い環境ですか？',
    answer_type: 2,
    options: [''],
    options_json: {
      '1': 'だいたいこんなもん',
      '2': 'やや少なく感じる',
      '3': 'やや多く感じる',
      '4': '多すぎる',
    },
  },
  {
    id: 2,
    seqno: 2,
    question_text:
      '今なにか困っていること、心配なことなどがあれば教えてください。',
    answer_type: 1,
    options: [''],
    options_json: [],
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: questions,
  }),
  post: () => ({
    status: 201,
    resBody: questions,
  }),
})
