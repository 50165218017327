import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      mail_subject: string
      mail_body: string
    }
    resBody: {
      mail_subject: string
      mail_body: string
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      mail_subject: '回答ありがとうございます！（ 03分HR OnBoardアンケート）',
      mail_body: `テスト 太郎 さん
03分のHR OnBoardアンケートにご回答いただき、ありがとうございます。
さっそく、回答を拝見します。
（回答確認後、担当者から改めて連絡させていただく場合があります。）
次回もぜひ、よろしくお願いします。
※本メールは自動で送付されています。返信は本文下部のメールアドレスまでお願いします。※
━━━━━━━━━━━━━━━━━━━━━━━━━━━
テスト株式会社
テスト組織 テスト差出人 太郎
[E-mail] test@en-japan.com
━━━━━━━━━━━━━━━━━━━━━━━━━━━`,
    },
  }),
})
