import type { DefineMethods } from 'aspida'
import type { Member } from '~/types/member'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: {
        members: Member[]
      }
    }
  }
}>
const members = [
  {
    id: 1,
    name: '田中真紀子',
    email: 'makiko@tanaka.com',
    department_id: 1,
    department_name: '資材部',
  },
] as Member[]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        members,
      },
    },
  }),
})
