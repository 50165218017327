import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    resBody: {
      success: boolean
    }
  }
}>
const read = {
  success: true,
}

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: read,
  }),
})
