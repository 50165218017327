import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MessageTemplate } from '~/types/messageTemplate'

export type Methods = DefineMethods<{
  get: {
    resBody: MessageTemplate[]
  }
}>

const masterMessageTemplates = [
  {
    id: 4,
    code: '630eff1b380505a67570dff952ce4ad720210104151060',
    name: '【既定】コンディション：晴れ',
    subject: 'メッセージをご確認ください',
    context:
      'こんにちは、#差出人 名前#です。\n\n\
      今月のアンケートにご回答いただきまして、\n\
      ありがとうございました。\n\n\
      回答いただいた内容から、日頃の業務に前向き\n\
      に取り組んでいらっしゃる様子が伺えました。\n\n\
      今後も毎月1日にアンケートをお送りしますので相談\n\
      したいこと等がありましたら、何でも記載してください。\n\n\
      #名前 姓#さんのこれからの活躍に向けてサポートを\n\
      していきたいと思っています。\n\n\
      それでは、引き続きよろしくお願いします。\n\n\
      ※ 返信の際は、送信元のアドレスを変えずにご返信ください\n\
       ※━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\
       #差出人 社名#\n\
       #差出人 組織# / #差出人 名前#\n\
       ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━',
    note: '※既定メッセージのため、編集・削除はできません。',
  },
  {
    id: 5,
    code: '630eff1b380505a67570dff952ce4ad720210104151061',
    name: '【既定】コンディション：雨／曇り（職場環境）',
    subject: 'メッセージをご確認ください',
    context:
      '今月のアンケートにご回答いただきまして、\n\
      ありがとうございました。\n\n\
      職場の環境などに慣れていないと回答されているのを見て、\n\
      少し心配になりご連絡しました。\n\n\
      もしよろしければ、そう思われた出来事などを教えて\n\
      頂きたいと思っています。一緒に解決策を考えさせて\n\
      ください。特に気になることがないようでしたら、\n\
      その旨教えて頂ければと思います。\n\n\
      #名前 姓#さんのこれからの活躍に向けてサポートを\n\
      していきたいと思っています。\n\n\
      ご返信お待ちしています。よろしくお願いします。\n\n\
      ※ 返信の際は、送信元のアドレスを変えずにご返信ください\n\
       ※━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\
       #差出人 社名#\n\
       #差出人 組織# / #差出人 名前#\n\
       ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━',
    note: '※既定メッセージのため、編集・削除はできません。',
  },
  {
    id: 6,
    code: '630eff1b380505a67570dff952ce4ad720210104151062',
    name: '【既定】コンディション：雨／曇り（上司）',
    subject: 'メッセージをご確認ください',
    context:
      'こんにちは、#差出人 名前#です。\n\n\
      今月のアンケートにご回答いただきまして、\n\
      ありがとうございました。\n\n\
      上司に話しかけづらいと回答されているのを見て、\n\
      少し心配になりご連絡しました。\n\n\
      もしよろしければ、そう思われた出来事などを教えて\n\
      頂きたいと思っています。一緒に解決策を考えさせて\n\
      ください。特に気になることがないようでしたら、\n\
      その旨教えて頂ければと思います。\n\n\
      #名前 姓#さんのこれからの活躍に向けてサポートを\n\
      していきたいと思っています。\n\n\
      ご返信お待ちしています。よろしくお願いします。\n\n\
      ※ 返信の際は、送信元のアドレスを変えずにご返信ください\n\
      ※━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\
      #差出人 社名#\n#差出人 組織# / #差出人 名前#\n\
      ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━',
    note: '※既定メッセージのため、編集・削除はできません。',
  },
  {
    id: 7,
    code: '630eff1b380505a67570dff952ce4ad720210104151063',
    name: '【既定】コンディション：雨／曇り（業務量）',
    subject: 'メッセージをご確認ください',
    context:
      'こんにちは、#差出人 名前#です。\n\n\
      今月のアンケートにご回答いただきまして、\n\
      ありがとうございました。\n\n\
      業務量が多いと回答されているのを見て、\n\
      少し心配になりご連絡しました。\n\n\
      もしよろしければ、そう思われた出来事などを教えて\n\
      頂きたいと思っています。一緒に解決策を考えさせて\n\
      ください。特に気になることがないようでしたら、\n\
      その旨教えて頂ければと思います。\n\n\
      #名前 姓#さんのこれからの活躍に向けてサポートを\n\
      していきたいと思っています。\n\n\
      ご返信お待ちしています。よろしくお願いします。\n\n\
      ※ 返信の際は、送信元のアドレスを変えずにご返信ください\n\
       ※━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\
       #差出人 社名#\n\
       #差出人 組織# / #差出人 名前#\n\
       ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━',
    note: '※既定メッセージのため、編集・削除はできません。',
  },
  {
    id: 8,
    code: '630eff1b380505a67570dff952ce4ad720210104151064',
    name: '【既定】フリーコメントへの返信',
    subject: 'メッセージをご確認ください',
    context:
      'こんにちは、#差出人 名前#です。\n\n\
      今月のアンケートにご回答いただきまして、\n\
      ありがとうございました。\n\n\
      フリーコメントに記入頂きありがとうございました。\n\n\
      ーーーーーーー\n\n\
      ※※フリーコメントから引用した文章を記載しましょう※※\n\n\
      ーーーーーーー\n\n\
      ★受け止めの言葉を記載\n\n\
      例：\n\
      「入社した直後で、他部署との連携の方法を理解するのは難しいですよね。」\n\
      「異業種から転職されているためギャップに感じることも多いですよね。」\n\n\
      ★アドバイスを記載\n\n\
      例：\n\
      「1つだけお伝えしますと、～～～～」\n\
      「私が以前同じ部署にいた時に心がけていたことなのですが、～～～～」\n\n\
      いかがでしょうか。このメールが#名前 姓#さんの\n\
      お役に立てればうれしいです。\n\n\
      今後も毎月1日にアンケートをお送りしますので相談\n\
      したいこと等がありましたら、何でも記載してください。\n\n\
      #名前 姓#さんのこれからの活躍に向けてサポートを\n\
      していきたいと思っています。\n\n\
      それでは、引き続きよろしくお願いします。\n\n\
      ※ 返信の際は、送信元のアドレスを変えずにご返信ください ※\n\n\n\
      ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\
      #差出人 社名#\n\
      #差出人 組織# / #差出人 名前#\n\
      ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━',
    note: '★マークの箇所は送信時に削除してください\t※既定メッセージのため、編集・削除はできません。',
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: masterMessageTemplates,
  }),
})
