import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MessageSearchItem } from '~/types/messageSearchItem'
import type { MessageSearchQuery } from '~/types/messageSearchQuery'

export type Methods = DefineMethods<{
  get: {
    query: MessageSearchQuery
    resBody: {
      result: {
        count: number
        members: MessageSearchItem[]
      }
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        count: 11,
        members: [
          {
            id: 1,
            code: '8540942181',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 開発部',
              short_name: '開発部',
            },
            image_uri: '/img/mock/user1.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '高橋',
            firstname: '理沙',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: false,
            message_read: 1,
            sending_at: '2021-03-01',
            email: 'test@en-japan.com',
          },
          {
            id: 2,
            code: '9993434304',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 営業部',
              short_name: '営業部',
            },
            image_uri: '/img/mock/user2.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '宮崎',
            firstname: '裕美',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: false,
            message_read: 1,
            sending_at: '2021-02-01',
            email: 'test@en-japan.com',
          },
          {
            id: 3,
            code: '6819148994',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 開発部',
              short_name: '開発部',
            },
            image_uri: '/img/mock/user3.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '西田',
            firstname: '聡',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: true,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 4,
            code: '1596494267',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 営業部',
              short_name: '営業部',
            },
            image_uri: '/img/mock/user4.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '川崎',
            firstname: '亜紀',
            working_status: 1,
            has_past_1_year: 1,
            is_stopped_send_questions_mail: true,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 5,
            code: '7140965380',
            department: {
              id: 3,
              full_path: 'デモ株式会社 > 経理部',
              short_name: '経理部',
            },
            image_uri: '/img/mock/user5.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '渡邊',
            firstname: '洋平',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: true,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 6,
            code: '8515856096',
            department: {
              id: 4,
              full_path: 'デモ株式会社 > 総務部',
              short_name: '総務部',
            },
            image_uri: '/img/mock/user6.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '星野',
            firstname: '美希',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: true,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 7,
            code: '7522851289',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 営業部',
              short_name: '営業部',
            },
            image_uri: '/img/mock/user7.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '竹内',
            firstname: '浩一',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: false,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 8,
            code: '6467494799',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 開発部',
              short_name: '開発部',
            },
            image_uri: '/img/mock/user8.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '梅林',
            firstname: '文哉',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: false,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 9,
            code: '6964704721',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 営業部',
              short_name: '営業部',
            },
            image_uri: '/img/mock/user9.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '遠藤',
            firstname: 'ひかり',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: true,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 10,
            code: '6265434524',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 営業部',
              short_name: '営業部',
            },
            image_uri: '/img/mock/user12.png',
            date_contracted: '2020-04-01',
            anniv_sent: 1,
            anniv_answer_completed: 1,
            lastname: '宇野',
            firstname: '達朗',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: false,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
          {
            id: 11,
            code: '2489945860',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 営業部',
              short_name: '営業部',
            },
            image_uri: '/img/mock/user11.png',
            date_contracted: '2020-04-01',
            anniv_sent: 0,
            anniv_answer_completed: 0,
            lastname: '鈴木',
            firstname: '陽介',
            working_status: 1,
            has_past_1_year: 0,
            is_stopped_send_questions_mail: false,
            message_read: 0,
            sending_at: '2021-01-01',
            email: 'test@en-japan.com',
          },
        ],
      },
    },
  }),
})
