import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    resBody: {
      token: string
      expiresIn: string
      message: string
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      token: '',
      expiresIn: '',
      message: 'ok',
    },
  }),
})
