import type { DefineMethods } from 'aspida'
import type { Senior } from '~/types/senior'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Senior[]
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: [
      {
        id: 1,
        department_id: 1,
        department_name: '総務部',
        department: '総務部',
        email: '7886d146b8@en-japan.com',
        receiving_report: true,
        receiving_bcc: true,
        firstname: '美奈',
        lastname: '河野',
      },
      {
        id: 2,
        department_id: 2,
        department_name: '営業部',
        department: '営業部',
        email: '7984ec735c@en-japan.com',
        receiving_report: true,
        receiving_bcc: true,
        firstname: '朝子',
        lastname: '坂本',
      },
    ],
  }),
})
