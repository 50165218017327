import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { DepartmentTreeViewItem } from '~/types/department'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: DepartmentTreeViewItem[]
    }
  }
  post: {
    reqBody: {
      parent_id: number
      name: string | undefined
      department_manager_id: number | undefined | null
      is_blank: boolean
    }
    resBody: {
      message: string
    }
  }
}>

const departments = [
  {
    id: 1,
    name: 'デモ株式会社',
    department_name: 'デモ株式会社',
    department_manager_name: null,
    department_manager_id: null,
    is_blank: false,
    parent_id: null,
    parent_name: '',
    is_archived: false,
    member_count: 10,
    has_descendants: true,
    parent_departments: [],
    enable_archive: false,
    descendants: [
      {
        id: 2,
        name: '営業部',
        department_name: '営業部',
        department_manager_name: '田中 太郎',
        department_manager_id: 1,
        parent_id: 1,
        parent_name: 'デモ株式会社',
        is_blank: false,
        member_count: 1,
        has_descendants: false,
        // parent_departments: [{ id: 1, name: 'デモ株式会社' }],
        enable_archive: true,
        is_archived: false,
        descendants: null,
      },
      {
        id: 3,
        name: '管理部',
        department_name: '管理部',
        department_manager_name: '',
        department_manager_id: null,
        parent_id: 1,
        parent_name: 'デモ株式会社',
        is_blank: false,
        member_count: 1,
        has_descendants: false,
        // parent_departments: [{ id: 1, name: 'デモ株式会社' }],
        enable_archive: true,
        is_archived: false,
        descendants: null,
      },
    ],
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: departments
    },
  }),
  post: () => ({
    status: 200,
    resBody: {
      message: '追加に成功しました',
    },
  }),
})
