import type { DefineMethods } from 'aspida'
import type { Viewer } from '~/types/viewer'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Viewer[]
  }
  post: {
    reqBody: {
      name?: string
      department?: string
    }

    resBody: {
      data: object
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: [
      {
        id: 1,
        name: '清水 健二',
        department: '人事部',
      },
      {
        id: 2,
        name: '清原 夏菜子',
        department: '人事部',
      },
      {
        id: 3,
        name: '清沢 和則',
        department: '人事部',
      },
    ],
  }),
  post: () => ({
    status: 200,
    resBody: {
      data: {
        status: 'success',
      },
    },
  }),
})
