import type { DefineMethods } from 'aspida'
import type { ThanksMailResponse } from '~/types/thanksMailResponse'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: ThanksMailResponse
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      mail_subject:
        '回答ありがとうございます！（ #配信月#分HR OnBoardアンケート）',
      mail_body: `#名前# さん（#名前 姓# #名前 名#）
#配信月#分のHR OnBoardアンケートにご回答いただき、ありがとうございます。
さっそく、回答を拝見します。
（回答確認後、担当者から改めて連絡させていただく場合があります。）
次回もぜひ、よろしくお願いします。
※本メールは自動で送付されています。返信は本文下部のメールアドレスまでお願いします。※
━━━━━━━━━━━━━━━━━━━━━━━━━━━
#差出人 社名#
#差出人 組織# #差出人 名前#
[E-mail] #返信先メールアドレス#
━━━━━━━━━━━━━━━━━━━━━━━━━━━`,
      default_mail_subject:
        '回答ありがとうございます！（ #配信月#分HR OnBoardアンケート）',
      default_mail_body: `#名前# さん
#配信月#分のHR OnBoardアンケートにご回答いただき、ありがとうございます。
さっそく、回答を拝見します。
（回答確認後、担当者から改めて連絡させていただく場合があります。）
次回もぜひ、よろしくお願いします。
※本メールは自動で送付されています。返信は本文下部のメールアドレスまでお願いします。※
━━━━━━━━━━━━━━━━━━━━━━━━━━━
#差出人 社名#
#差出人 組織# #差出人 名前#
[E-mail] #返信先メールアドレス#
━━━━━━━━━━━━━━━━━━━━━━━━━━━`,
    },
  }),
})
