import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    query: {
      category: string
      start_date: string
      end_date: string
      limit: number
      offset: number
    }
    resFormat: FormData
    resBody: Blob
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: new Blob(),
  }),
})
