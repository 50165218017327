import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      talent_member_id: string
    }

    resBody: {
      pdf_url: string
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      pdf_url: 'https://talent/pdf',
    },
  }),
})
