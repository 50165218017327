import type { DefineMethods } from 'aspida'
import type { TempMember } from '~/types/tempMember'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  delete: {
    resBody: TempMember
  }
  get: {
    resBody: TempMember
  }
}>

const tempMembersMockData = {
  id: 1,
  company_id: 1,
  joined_site_id: 1,
  joined_company_id: 1,
  joined_work_id: 1,
  joined_user_id: 1,
  occupation_name: '営業',
  lastname: 'エン',
  firstname: '太郎',
  lastname_kana: 'スズキ',
  firstname_kana: 'イチロウ',
  employing_type: 1,
  working_status: 1,
  gender: 1,
  birth_year: 2000,
  birth_month: 1,
  birth_day: 1,
  initial_evaluation: 1,
  is_newbie: 0,
  initial_evaluated_point: '人柄が良い',
  initial_worry_point: '姿勢が悪い',
  route_employed: 1,
  route_employed_detail: 'en転職',
  date_contracted: '2020-01-01',
  register_status: 0,
  created_at: '2020-01-01 00:00:00',
  updated_at: '2020-01-01 00:00:00',
}

export default mockMethods<Methods>({
  delete: () => ({
    status: 200,
    resBody: tempMembersMockData,
  }),
  get: () => ({
    status: 200,
    resBody: tempMembersMockData,
  }),
})
