import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { CustomMonthlyQuestion } from '~/types/customMonthlyQuestion'

export type Methods = DefineMethods<{
  delete: {
    resBody: []
  }
  put: {
    reqBody: CustomMonthlyQuestion
    resBody: CustomMonthlyQuestion[]
  }
  post: {
    reqBody: CustomMonthlyQuestion
    resBody: CustomMonthlyQuestion[]
  }
}>

const question = {
  id: 1,
  seqno: 1,
  question_text: '直近の労働勤務時間はあなたにとって多い環境ですか？',
  answer_type: 2,
  options: [''],
  options_json: {
    '1': 'だいたいこんなもん',
    '2': 'やや少なく感じる',
    '3': 'やや多く感じる',
    '4': '多すぎる',
  },
}

export default mockMethods<Methods>({
  delete: () => ({
    status: 200,
    resBody: [],
  }),
  put: () => ({
    status: 200,
    resBody: [question],
  }),
  post: () => ({
    status: 201,
    resBody: [question],
  }),
})
