/**
 * @file Enable vue-scrollto
 */
import VueScrollTo from 'vue-scrollto'

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(VueScrollTo, {
    offset: -140, // 固定ヘッダーがある場合に、オフセットで調整する
  })
})
