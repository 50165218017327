import {
  initializeFlashMessage,
  appFlashMessageKey,
} from '~/composables/useFlashMessages'
import AppFlashMessage from '~/components/ui/AppFlashMessage/index.vue'

/* eslint-disable  @typescript-eslint/no-explicit-any */
const appFlashMessagePlugin = {
  install(app: any): void {
    app.provide(appFlashMessageKey, initializeFlashMessage())
    app.component('AppFlashMessage', AppFlashMessage)
  },
}
export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(appFlashMessagePlugin)
})
