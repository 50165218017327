import { initializeLoading, appLoadingKey } from '~/composables/useLoading'
import AppLoading from '~/components/ui/AppLoading/index.vue'

/* eslint-disable  @typescript-eslint/no-explicit-any */
const appLoadingPlugin = {
  install(app: any): void {
    app.provide(appLoadingKey, initializeLoading())
    app.component('AppLoading', AppLoading)
  },
}
export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(appLoadingPlugin)
})
