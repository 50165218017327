import type { DefineMethods } from 'aspida'
import type { User } from '~/types/user'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    query?: {
      ip: string
    }
    resBody: User
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      currentIp: '172.0.0.1',
      current_ip_is_valid: true,
      department: '人事部',
      email: 'yukari_en@en-japan.com',
      has_security_log: false,
      id: 9314,
      image_uri: '/img/mock/user1.png',
      name: '縁ゆかり',
      new_password_needed: false,
      roles: [
        {
          key: 'owner',
          name: 'OWNER',
          value: 'owner',
          label: '全体管理アカウント',
          short_name: 'オーナー',
          permissions: [],
          type: 'administrator',
        },
      ],
      permissions: [
        'security_setting_read',
        'security_setting_update',
        'department_read',
        'department_create',
        'department_update',
        'department_delete',
        'department_manager_read',
        'department_manager_create',
        'department_manager_update',
        'department_manager_delete',
        'user_read',
        'user_create',
        'user_update',
        'user_delete',
        'senior_read',
        'senior_create',
        'senior_update',
        'senior_delete',
        'notification_read',
        'member_read',
        'member_create',
        'member_update',
        'member_delete',
        'message_create',
        'enq_setting_initial_setup_read',
        'enq_setting_initial_setup_update',
        'enq_setting_basic_read',
        'enq_setting_basic_update',
        'enq_setting_advanced_read',
        'enq_setting_advanced_update',
        'enq_setting_notification_options_read',
        'enq_setting_notification_options_update',
        'message_setting_template_read',
        'message_setting_template_update',
        'message_setting_auto_delivery_read',
        'message_setting_auto_delivery_update',
        'message_setting_other_read',
        'message_setting_other_update',
        'report_read',
      ],
    },
  }),
})
