import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqFormat: FormData
    reqBody: {
      lastname: string
      firstname: string
      lastname_kana?: string
      firstname_kana?: string
      employee_code?: string
      employing_type: number
      working_status: number
      date_resigned?: string
      resign_reason?: string
      resign_reason_text?: string
      gender: number
      birth_year: string
      birth_month: string
      birth_day: string
      mobile_tel_number?: string
      email: string
      date_contracted: string
      delivery_date: string
      department_id?: number
      profession?: string
      senior_id?: number
      initial_evaluation: number
      is_newbie: number
      entry_reason?: string
      recruiter?: string
      initial_evaluated_point?: string
      initial_worry_point?: string
      route_employed?: number
      route_employed_detail?: string
      job_change_count?: number
      working_years?: number
      graduation_type?: number
      ex_company?: string
      ex_profession?: string
      other_info?: string
      other_info2?: string
      other_info3?: string
      is_stopped_send_questions_mail?: number
      is_stopped_send_anniv_mail?: number
      mail_language: number
      avatar_file?: Blob
    }

    resBody: {
      result: {
        id: number
        code: string
      }
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 201,
    resBody: {
      result: {
        id: 1,
        code: '1',
      },
      message: 'メンバーを登録しました',
    },
  }),
})
