import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

type Response = {
  ip: string
}

export type Methods = DefineMethods<{
  get: {
    resBody: Response
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      ip: '172.0.0.1',
    },
  }),
})
