// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import {
  VCombobox,
  VListItem,
  VTooltip,
  VAutocomplete,
  VSelect,
} from 'vuetify/components'

export default defineNuxtPlugin(app => {
  const vuetify = createVuetify({
    components: {
      VCombobox,
      VListItem,
      VTooltip,
      VAutocomplete,
      VSelect,
    },
  })
  app.vueApp.use(vuetify)
})
