import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      count: number
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      count: 10,
    },
  }),
})
