import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: {
        is_enq_complete_notice_cloudy: boolean
        is_enq_complete_notice_rainy: boolean
        is_enq_complete_notice_sunny: boolean
      }
    }
  }
  put: {
    reqBody: {
      is_enq_complete_notice_rainy: boolean
      is_enq_complete_notice_cloudy: boolean
      is_enq_complete_notice_sunny: boolean
    }
    resBody: {
      message: string
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        is_enq_complete_notice_cloudy: true,
        is_enq_complete_notice_rainy: true,
        is_enq_complete_notice_sunny: false,
      },
    },
  }),
  put: () => ({
    status: 200,
    resBody: {
      message: '月次回答通知設定を保存しました',
    },
  }),
})
