import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      department_id_list: number[]
    }
    resBody: {
      message: string
      result: {
        unarchived_count: number
        archived_count: number
        unarchived_departments: [
          {
            id: number
            department_name: string
          },
        ]
        archived_departments: [
          {
            id: number
            department_name: string
          },
        ]
      }
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      message: 'アーカイブに成功しました',
      result: {
        unarchived_count: 0,
        archived_count: 1,
        archived_departments: [
          {
            id: 1,
            department_name: '',
          },
        ],
        unarchived_departments: [
          {
            id: 1,
            department_name: '',
          },
        ],
      },
    },
  }),
})
