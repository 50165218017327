import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { AnniversarySearchQuery } from '~/types/anniversary'

export type Methods = DefineMethods<{
  get: {
    responseType: 'blob'
    resBody: Blob
    query?: AnniversarySearchQuery
  }
}>
export default mockMethods<Methods>({
  get: () => ({
    responseType: 'blob',
    status: 200,
    resBody: new Blob(['']),
  }),
})
