import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      mail_subject: string
      mail_body: string
    }
    resBody: object
  }
}>

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: {
      success: true,
    },
  }),
})