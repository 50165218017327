import type { DefineMethods } from 'aspida'
import type { RoleKey } from '~/types/roleKey'
import type { User } from '~/types/user'
import type { Role } from '~/types/role'
import type { UsersDepartmentListItem } from '~/types/department'
import type { Member } from '~/types/member'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqFormat: FormData
    reqBody: {
      image?: Blob
      name?: string
      email?: string
      department?: string | null
    }
    resBody: {
      message: string
      result: {
        company: Company
        roles: Role[]
        id: number
        company_id: number
        department_id: number | null
        lastname: string
        firstname: string
        fullname: string
        email: string
        remember_token: string | null
        image_uri: string
        department: string | null
        display_limit: boolean
        is_first_account: boolean
        is_owner: boolean
        is_send_start_mail: boolean
        is_send_monthly_mail: boolean
        is_send_anniversary_mail: boolean
        is_send_summary_mail: boolean
        created_at: string
        updated_at: string
      }
    }
  }
  put: {
    reqBody: {
      role_key: RoleKey
      appliedList?: []
      department_id?: number
      display_limit?: boolean
      display_setting?: {
        departments: number[] | []
        members: number[] | []
      }
      email?: string
      firstname?: string
      lastname?: string
      fullname?: string
      id?: number
      permissions?: PermissionName[] | []
      roles?: Role[] | [] | null
    }
    resBody: {
      status: number
      message: string
      result: User
    }
  }
  get: {
    resBody: {
      result: {
        user: User
        roles: Role[]
        departments: UsersDepartmentListItem[]
        members: Member[]
      }
    }
  }
  delete: {
    resBody: {
      message: string
    }
  }
}>
const mockUsers: Record<number, User> = {
  1: {
    id: 1,
    lastname: '河野',
    firstname: '美奈',
    department_id: 3,
    email: 'mina_k@en-japan.com',
    roles: [
      {
        key: 'super_administrator',
        name: '全体管理アカウント',
        short_name: '全体管理',
      },
    ],
    permissions: [],
    display_limit: false,
    display_setting: {
      departments: [],
      members: [],
    },
  },
  2: {
    id: 2,
    lastname: '坂本',
    firstname: '朝子',
    department_id: 2,
    email: 'asako_s@en-japan.com',
    roles: [
      {
        key: 'super_administrator',
        name: '全体管理アカウント',
        short_name: '全体管理',
      },
    ],
    permissions: [],
    display_limit: false,
    display_setting: {
      departments: [],
      members: [],
    },
  },
  3: {
    id: 3,
    lastname: '三島',
    firstname: 'カナ',
    department_id: 3,
    email: 'kana_m@en-japan.com',
    roles: [
      {
        key: 'standard_viewer',
        name: '標準閲覧アカウント',
        short_name: '標準閲覧',
      },
    ],
    permissions: [],
    display_limit: false,
    display_setting: {
      departments: [
        { id: 2, department_name: 'デモ株式会社 > 営業部' },
        { id: 3, department_name: 'デモ株式会社 > 管理部' },
      ],
      members: [
        {
          id: 2,
          name: '鈴木次郎',
          email: 'jiro@suzuki.com',
        },
        {
          id: 3,
          name: '早乙女潤',
          email: 'jun@saotome.com',
        },
      ],
    },
  },
  4: {
    id: 4,
    lastname: '四門',
    firstname: '洋子',
    department_id: 2,
    email: 'yoko_s@en-japan.com',
    roles: [
      {
        key: 'restricted_viewer',
        name: '制限閲覧アカウント',
        short_name: '制限閲覧',
      },
    ],
    permissions: [],
    display_limit: true,
    display_setting: {
      departments: [
        { id: 1, department_name: 'デモ株式会社' },
        { id: 2, department_name: 'デモ株式会社 > 営業部' },
      ],
      members: [
        {
          id: 2,
          name: '鈴木次郎',
          email: 'jiro@suzuki.com',
        },
        {
          id: 3,
          name: '早乙女潤',
          email: 'jun@saotome.com',
        },
      ],
    },
  },
}
const departments: UsersDepartmentListItem[] = [
  { id: 1, department_name: 'デモ株式会社' },
  { id: 2, department_name: 'デモ株式会社 > 営業部' },
  { id: 3, department_name: 'デモ株式会社 > 管理部' },
]
const roles = [
  {
    key: 'super_administrator' as const,
    name: '全体管理アカウント',
    short_name: '全体管理',
    type: 'administrator',
    permissions: [],
    value: '',
    label: '',
  },
  {
    key: 'limited_administrator' as const,
    name: '限定管理アカウント',
    short_name: '限定管理',
    type: 'administrator',
    permissions: [],
    value: '',
    label: '',
  },
  {
    key: 'standard_viewer' as const,
    name: '標準閲覧アカウント',
    short_name: '標準閲覧',
    type: 'viewer',
    permissions: [],
    value: '',
    label: '',
  },
  {
    key: 'restricted_viewer' as const,
    name: '制限閲覧アカウント',
    short_name: '制限閲覧',
    type: 'viewer',
    permissions: [],
    value: '',
    label: '',
  },
]
const members = [
  {
    id: 1,
    name: '田中太郎',
    email: 'taro@tanaka.com',
    department_name: '総務部',
  },
  {
    id: 2,
    name: '鈴木次郎',
    email: 'jiro@suzuki.com',
    department_name: '総務部',
  },
  {
    id: 3,
    name: '早乙女潤',
    email: 'jun@saotome.com',
    department_name: '総務部',
  },
] as Member[]

type Company = {
  id: number
  name: string
  max_account_count: number
  max_reading_account_count: number
  max_member_count: number
  first_send_mail_flag: boolean
  is_send_start_mail: boolean
  is_send_monthly_mail: boolean
  is_send_anniversary_mail: boolean
  is_send_summary_mail: boolean
  is_enq_complete_notice: boolean
  is_enq_complete_notice_rainy: boolean
  is_enq_complete_notice_cloudy: boolean
  is_enq_complete_notice_sunny: boolean
  enq_complete_notice_email_bounce_count: number
  created_at: string
  updated_at: string
}
const company = {
  id: 1,
  name: 'エン太郎',
  max_account_count: 100,
  max_reading_account_count: 100,
  max_member_count: 100,
  first_send_mail_flag: true,
  is_send_start_mail: true,
  is_send_monthly_mail: true,
  is_send_anniversary_mail: true,
  is_send_summary_mail: true,
  is_enq_complete_notice: false,
  is_enq_complete_notice_rainy: false,
  is_enq_complete_notice_cloudy: false,
  is_enq_complete_notice_sunny: false,
  enq_complete_notice_email_bounce_count: 0,
  created_at: '2024/07/10',
  updated_at: '2024/07/10',
}
export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      message: 'アカウント情報を更新しました',
      result: {
        roles,
        company,
        id: 1,
        company_id: 1,
        department_id: null,
        lastname: 'エン',
        firstname: '太郎',
        fullname: 'エン太郎',
        email: 'taro@en.com',
        remember_token: null,
        image_uri: '',
        department: null,
        display_limit: false,
        is_first_account: false,
        is_owner: false,
        is_send_start_mail: false,
        is_send_monthly_mail: false,
        is_send_anniversary_mail: false,
        is_send_summary_mail: false,
        created_at: '',
        updated_at: '',
      },
    },
  }),
  put: ({ path }) => {
    const idString = path.split('/').pop()
    const id = idString ? parseInt(idString, 10) : NaN
    const user = mockUsers[id] || mockUsers[1]
    return {
      status: 200,
      resBody: {
        status: 200,
        message: 'アカウント情報を更新しました',
        result: user,
      },
    }
  },
  get: ({ path }) => {
    // pathからuser_idを取得して該当のUserを返却（該当ない場合、id=1を固定で返却）
    const idString = path.split('/').pop()
    const id = idString ? parseInt(idString, 10) : NaN
    const user = mockUsers[id] || mockUsers[1]
    return {
      status: 200,
      resBody: {
        result: {
          user, // 抽出した ID に対応するユーザーを返す
          roles,
          departments,
          members,
        },
      },
    }
  },
  delete: () => ({
    status: 200,
    resBody: {
      message: 'アカウント情報を削除しました',
    },
  }),
})
