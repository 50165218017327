import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MailTestPreview } from '~/types/mailTestPreview'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      date: string
      send: boolean
      member_id: number | null
      condition_type: number | null
    }
    resBody: MailTestPreview
  }
}>

const res = {
  mailService: {
    filterCompanyId: [0],
    filterMemberId: [0],
    filterSeniorId: 0,
    annivExpiryDay: 0,
    isDebug: 0,
    createReservedEmailRecord: true,
    mailSubject: '',
    mailBody: '',
    slackBody: '',
    smsBody: '',
    lineBody: '',
    lineWorksBody: '',
    spAppBody: '',
    mailable: null,
  },
}

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: res,
  }),
})
