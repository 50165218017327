import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  put: {
    reqBody: {
      department_manager_id: number | null
      id: number
      name: string
      parent_id: number | null
    }
    resBody: {
      message: string
    }
  }
}>
const department = {
  id: 1,
  name: 'デモ株式会社',
  department_manager_name: '',
  parent_id: null,
  parent_name: '',
  is_blank: false,
  member_count: 10,
}

export default mockMethods<Methods>({
  put: () => ({
    status: 200,
    resBody: {
      message: '更新に成功しました',
    },
  }),
})
