/**
 * @file aspida/axios client
 */
import axios from 'axios'
import { default as aspida, default as aspidaClient } from '@aspida/axios'
import api from '../../api/$api'
import mock from '../../api/$mock'
import files from '../../files/$api'

export default defineNuxtPlugin(() => {
  const $config = useRuntimeConfig()
  const client =
    $config.public.APP_ENV === 'demo'
      ? mock(aspidaClient(), { log: true })
      : api(
          aspida(axios, {
            baseURL: `${$config.public.BASE_URL}`,
            // @NOTE
            // バックエンドの設定と合わせています
            // PHP の max_execution_time は Interactor で 300秒に設定している
            // 300秒＝300000ミリ秒
            timeout: 300000,
            withCredentials: true,
          }),
        )
  const fileClient = files(
    aspida(axios, {
      baseURL: `${$config.public.FILE_BASE_URL}`,
      responseType: 'blob',
      timeout: 300000,
      withCredentials: true,
    }),
  )

  return {
    provide: {
      client,
      fileClient,
    },
  }
})
