import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MemberSearchItem } from '~/types/member'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      members: MemberSearchItem[],
      mailSubject: string
      mailBody: string
      messageId?: string
    }
    resBody: {}
  }
}>


export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {},
  }),
})