import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { DepartmentManager } from '~/types/departmentManager'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: DepartmentManager[]
    }
  }
  post: {
    reqBody: {
      email: string
      first_name: string
      last_name: string
      department_count: number
    }
    resBody: {
      message: string
    }
  }
}>

const getDepartmentManagers = [
  {
    id: 2,
    first_name: '次郎',
    last_name: '佐藤',
    email: 'satou@jiro.com',
    department_count: 1,
  },
  {
    id: 1,
    first_name: '湊',
    last_name: '水野',
    email: 'mizuno@minato.com',
    department_count: 2,
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: getDepartmentManagers,
    },
  }),
  post: () => ({
    status: 200,
    resBody: {
      message: '組織図に追加されました',
    },
  }),
})
