import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { AnniversarySearchQuery } from '~/types/anniversary'
import { format, subDays } from 'date-fns'
import type { AnniversaryMemberSearchItem } from '~/types/anniversary/memberSearchItem'
const dateFormat = 'yyyy-MM-dd'

export type Methods = DefineMethods<{
  get: {
    query?: AnniversarySearchQuery
    resBody: {
      result: {
        members: AnniversaryMemberSearchItem[]
        count: number
      }
    }
  }
}>

const members = [
  // 配信済み・既読
  {
    id: 1,
    code: '6265434524',
    department_short_name: '新規事業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    image_uri: '/img/mock/user1.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '安倍',
    firstname: '貴文',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    motivation: '3.8',
    success: '2.1',
    anniv_answer_read: 1,
    senior_anniv_answer_read: 1,
  },
  // 未配信
  {
    id: 2,
    code: '6265434525',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: '/img/mock/user2.png',
    date_contracted: format(subDays(new Date(), 364), dateFormat),
    condition_type: 1,
    lastname: '野上',
    firstname: '敦彦',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 0,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 0,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 未配信・配信停止設定
  {
    id: 3,
    code: '6265434526',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: '/img/mock/user3.png',
    date_contracted: format(subDays(new Date(), 1), dateFormat),
    condition_type: 1,
    lastname: '田中',
    firstname: '綾子',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 0,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: true,
    anniv_sent: 0,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 未回答
  {
    id: 4,
    code: '6265434527',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: '/img/mock/user4.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '佐藤',
    firstname: '拓哉',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 0,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: subDays(new Date(), 1),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 未回答・配信停止設定
  {
    id: 5,
    code: '6265434528',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: '/img/mock/user5.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '木村',
    firstname: '牧子',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 0,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: true,
    anniv_sent: 0,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 配信済み・未読
  {
    id: 6,
    code: '6265434529',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: '/img/mock/user6.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '原',
    firstname: '舞',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    motivation: '3.8',
    success: '2.1',
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 配信済み・上司未読
  {
    id: 7,
    code: '6265434530',
    department_short_name: '情報システム部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 情報システム部',
      short_name: '情報システム部',
    },
    image_uri: '/img/mock/user7.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '市原',
    firstname: '七海',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    motivation: '3.8',
    success: '2.1',
    anniv_answer_read: 1,
    senior_anniv_answer_read: 0,
  },
  // 配信済み・本人未読
  {
    id: 8,
    code: '6265434531',
    department_short_name: '人事部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 人事部',
      short_name: '人事部',
    },
    image_uri: '/img/mock/user8.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '鈴木',
    firstname: '智子',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    motivation: '3.8',
    success: '2.1',
    anniv_answer_read: 0,
    senior_anniv_answer_read: 1,
  },
  // 配信済み・退職
  {
    id: 9,
    code: '6265434532',
    department_short_name: '人事部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 人事部',
      short_name: '人事部',
    },
    image_uri: '/img/mock/user9.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '山崎',
    firstname: '文',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 2,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 1,
    motivation: '3.8',
    success: '2.1',
    anniv_answer_read: 1,
    senior_anniv_answer_read: 1,
  },
  // 上司回答済み既読・本人未回答
  {
    id: 10,
    code: '6265434533',
    department_short_name: '人事部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 人事部',
      short_name: '人事部',
    },
    image_uri: '/img/mock/user8.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '田中',
    firstname: '雅美',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 1,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 1,
  },
  // 上司回答済み未読・本人未回答
  {
    id: 11,
    code: '6265434534',
    department_short_name: '総務部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 総務部',
      short_name: '総務部',
    },
    image_uri: '/img/mock/user11.png',
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '黒川',
    firstname: '秋',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 1,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司未回答・本人未読
  {
    id: 12,
    code: '6265434535',
    department_short_name: '総務部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 総務部',
      short_name: '総務部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '木村',
    firstname: '太郎',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 0,
    motivation: '4',
    success: '3',
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司未回答・本人既読
  {
    id: 13,
    code: '6265434536',
    department_short_name: '総務部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 総務部',
      short_name: '総務部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '大神',
    firstname: '将暉',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 0,
    motivation: '2',
    success: '2',
    anniv_answer_read: 1,
    senior_anniv_answer_read: 0,
  },
  // 上司未回答・本人未配信
  {
    id: 14,
    code: '6265434537',
    department_short_name: 'エンジニア部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > エンジニア部',
      short_name: 'エンジニア部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '徳川',
    firstname: '美智子',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司未読・本人未配信
  {
    id: 15,
    code: '6265434538',
    department_short_name: '法務部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 法務部',
      short_name: '法務部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '佐々木',
    firstname: '望',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 1,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司既読・本人未配信
  {
    id: 16,
    code: '6265434539',
    department_short_name: '総務部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 総務部',
      short_name: '総務部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 366), dateFormat),
    condition_type: 1,
    lastname: '明智',
    firstname: '光代',
    senior_id: 1,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 1), dateFormat),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 1,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 1,
  },
  // 上司なし・本人未配信
  {
    id: 17,
    code: '6265434540',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 363), dateFormat),
    condition_type: 1,
    lastname: '赤川',
    firstname: '直也',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 0,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司なし・本人配信停止
  {
    id: 18,
    code: '6265434541',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 363), dateFormat),
    condition_type: 1,
    lastname: '遠藤',
    firstname: '冬夜',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: true,
    anniv_sent: 0,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司なし・本人配信停止
  {
    id: 19,
    code: '6265434542',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 367), dateFormat),
    condition_type: 1,
    lastname: '高橋',
    firstname: '美和子',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: true,
    anniv_sent: 0,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司なし・本人未回答
  {
    id: 20,
    code: '6265434543',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 367), dateFormat),
    condition_type: 1,
    lastname: '御手洗',
    firstname: '大地',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 2), dateFormat),
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司なし・本人未読
  {
    id: 21,
    code: '6265434544',
    department_short_name: '営業部',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部',
      short_name: '営業部',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 367), dateFormat),
    condition_type: 1,
    lastname: '上野',
    firstname: '由美子',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 2), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 0,
    motivation: '3.5',
    success: '2.5',
    anniv_answer_read: 0,
    senior_anniv_answer_read: 0,
  },
  // 上司なし・本人既読
  {
    id: 22,
    code: '6265434545',
    department_short_name: '営業部第2',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部第2',
      short_name: '営業部第2',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 367), dateFormat),
    condition_type: 1,
    lastname: '芥川',
    firstname: '龍',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: format(subDays(new Date(), 2), dateFormat),
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 0,
    motivation: '3.5',
    success: '2.5',
    anniv_answer_read: 1,
    senior_anniv_answer_read: 0,
  },
  // 旧1年後アンケート
  {
    id: 23,
    code: '6265434546',
    department_short_name: '営業部第2',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 営業部第2',
      short_name: '営業部第2',
    },
    image_uri: null,
    date_contracted: format(subDays(new Date(), 367), dateFormat),
    condition_type: 1,
    lastname: '金刺',
    firstname: '美和',
    senior_id: 0,
    hr_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-12-31',
    importance: 1,
    employing_type: 1,
    working_status: 1,
    gender: 1,
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    initial_evaluation: 1,
    is_newbie: true,
    progress_status: 2,
    age: 21,
    has_past_1_year: 1,
    is_stopped_send_questions_mail: 0,
    message_read: 0,
    sending_at: '2021-01-01',
    is_stopped_send_anniv_mail: false,
    anniv_sent: 1,
    anniv_renewal_sent_date: null,
    anniv_answer_completed: 1,
    anniv_senior_answer_completed: 0,
    motivation: null,
    success: null,
    anniv_answer_read: 1,
    senior_anniv_answer_read: 1,
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        members,
        count: 23,
      },
    },
  }),
})
