import { default as loginufjgVT6wTKMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/auth/login.vue?macro=true";
import { default as _91token_93xeRQWSdcK7Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/auth/password_reset_window/[token].vue?macro=true";
import { default as authd9Ntivz72ZMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/auth.vue?macro=true";
import { default as _91company_93WXyiP7oDZvMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/case/[company].vue?macro=true";
import { default as draftXsFn6P2a73Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/case/draft.vue?macro=true";
import { default as indexo5dTsD4obJMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/case/index.vue?macro=true";
import { default as IndexautfhMYIGaMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/dashboard/Index.vue?macro=true";
import { default as faqAjsmFsUkE3Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/help/faq.vue?macro=true";
import { default as _91code_93RR9a3FBdYGMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/anniversary/[code].vue?macro=true";
import { default as indexZCFVKKL41DMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/anniversary/index.vue?macro=true";
import { default as pdfJnaQfzhHHXMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/anniversary/pdf.vue?macro=true";
import { default as csv_update89wDgUHv4GMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/csv_update.vue?macro=true";
import { default as indexG2gYYlLiZZMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/csv/index.vue?macro=true";
import { default as _91code_93BHlIncAEU1Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/detail/[code].vue?macro=true";
import { default as _91code_93ZnrwdoRwn6Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/form/[code].vue?macro=true";
import { default as indexPyAugmy376Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/form/index.vue?macro=true";
import { default as indexpgUEtC7jjJMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/import/index.vue?macro=true";
import { default as indexsIrBj3LAmOMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/Index/index.vue?macro=true";
import { default as membery5FkljwxmeMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/member.vue?macro=true";
import { default as _91code_93bBoU1BISh5Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/detail/[code].vue?macro=true";
import { default as _91code_93GRJrkfdrJHMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/form/[code].vue?macro=true";
import { default as indexA3pRcxW0lqMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/form/index.vue?macro=true";
import { default as indexgjLs55yKLuMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/Index/index.vue?macro=true";
import { default as messageiw36QWi2UfMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/message.vue?macro=true";
import { default as _91id_935lwFJFR3dzMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/notification/[id].vue?macro=true";
import { default as notificationuYVNsOKyTXMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/notification.vue?macro=true";
import { default as indexifEk61QhenMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/report/Index/index.vue?macro=true";
import { default as _91id_93nO6BwWrYljMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/account/department/detail/[id].vue?macro=true";
import { default as _91id_930UPpoa8NeDMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/account/department/form/[id].vue?macro=true";
import { default as accountaSJQpJZePRMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/account.vue?macro=true";
import { default as annual30bCs8Ie4nMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/annual.vue?macro=true";
import { default as indexqBtWuMQPSrMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/index.vue?macro=true";
import { default as indexnxD8RgjJYgMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/monthly/index.vue?macro=true";
import { default as thanks862H0k1pegMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/monthly/thanks.vue?macro=true";
import { default as indexILGAhPY1t8Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/index.vue?macro=true";
import { default as lineYS1T2RScFKMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/line.vue?macro=true";
import { default as slackwaZznALpPYMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/slack.vue?macro=true";
import { default as smsX9ldShW8WhMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/sms.vue?macro=true";
import { default as enqVK6CCW0TOfMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq.vue?macro=true";
import { default as importxaU0vixy4iMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/import.vue?macro=true";
import { default as _91code_93dHvCmiwDuuMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/message/[code].vue?macro=true";
import { default as create3LWRlhQhi5Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/message/create.vue?macro=true";
import { default as indexUGJbpY8k0GMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/message/index.vue?macro=true";
import { default as securityZYqJigXLJvMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/security.vue?macro=true";
import { default as talent_importYKDsfK9WZAMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/talent_import.vue?macro=true";
import { default as _91id_93saX7qA8vXdMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/temp_member/[id].vue?macro=true";
import { default as indexWEeqKjc3aqMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/test/Index/index.vue?macro=true";
import { default as mail_companyVhnvzz69LkMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/test/mail_company.vue?macro=true";
import { default as mail_personal0o5In5a8gEMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/test/mail_personal.vue?macro=true";
import { default as tutorialCUZA4lLVfcMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company/tutorial.vue?macro=true";
import { default as companySwPArpVgVQMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/company.vue?macro=true";
import { default as indexKHwbvcZbzeMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/engage/callback/index.vue?macro=true";
import { default as _91id_93THDMiu6T4pMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/features/[id].vue?macro=true";
import { default as indexvoTDIqjsojMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/features/index.vue?macro=true";
import { default as index8GONOkqvOlMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/index.vue?macro=true";
import { default as indexOWV1KE7eKEMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/introduction/index.vue?macro=true";
import { default as _91date_93vLEHuw4Q4GMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/news/[date].vue?macro=true";
import { default as draftbmCNsDRChMMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/news/draft.vue?macro=true";
import { default as indexNgwdSS2Cp0Meta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/news/index.vue?macro=true";
import { default as indexM3xYQ7KvEiMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/performance/index.vue?macro=true";
import { default as indexBIs9mf7EvVMeta } from "/home/runner/work/onboard/onboard/Code/app/src/pages/terms/index.vue?macro=true";
import { default as indexmZZiHwHNnbMeta } from "~/pages/news/index.vue?macro=true";
import { default as indexlrxaCla384Meta } from "~/pages/case/index.vue?macro=true";
export default [
  {
    name: authd9Ntivz72ZMeta?.name ?? "auth",
    path: authd9Ntivz72ZMeta?.path ?? "/auth",
    meta: authd9Ntivz72ZMeta || {},
    alias: authd9Ntivz72ZMeta?.alias || [],
    redirect: authd9Ntivz72ZMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: loginufjgVT6wTKMeta?.name ?? "auth-login",
    path: loginufjgVT6wTKMeta?.path ?? "login",
    meta: loginufjgVT6wTKMeta || {},
    alias: loginufjgVT6wTKMeta?.alias || [],
    redirect: loginufjgVT6wTKMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93xeRQWSdcK7Meta?.name ?? "auth-password_reset_window-token",
    path: _91token_93xeRQWSdcK7Meta?.path ?? "password_reset_window/:token()",
    meta: _91token_93xeRQWSdcK7Meta || {},
    alias: _91token_93xeRQWSdcK7Meta?.alias || [],
    redirect: _91token_93xeRQWSdcK7Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/auth/password_reset_window/[token].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91company_93WXyiP7oDZvMeta?.name ?? "case-company",
    path: _91company_93WXyiP7oDZvMeta?.path ?? "/case/:company()",
    meta: _91company_93WXyiP7oDZvMeta || {},
    alias: _91company_93WXyiP7oDZvMeta?.alias || [],
    redirect: _91company_93WXyiP7oDZvMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/case/[company].vue").then(m => m.default || m)
  },
  {
    name: draftXsFn6P2a73Meta?.name ?? "case-draft",
    path: draftXsFn6P2a73Meta?.path ?? "/case/draft",
    meta: draftXsFn6P2a73Meta || {},
    alias: draftXsFn6P2a73Meta?.alias || [],
    redirect: draftXsFn6P2a73Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/case/draft.vue").then(m => m.default || m)
  },
  {
    name: indexo5dTsD4obJMeta?.name ?? "case",
    path: indexo5dTsD4obJMeta?.path ?? "/case",
    meta: indexo5dTsD4obJMeta || {},
    alias: indexo5dTsD4obJMeta?.alias || [],
    redirect: indexo5dTsD4obJMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/case/index.vue").then(m => m.default || m)
  },
  {
    name: companySwPArpVgVQMeta?.name ?? "company",
    path: companySwPArpVgVQMeta?.path ?? "/company",
    meta: companySwPArpVgVQMeta || {},
    alias: companySwPArpVgVQMeta?.alias || [],
    redirect: companySwPArpVgVQMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: IndexautfhMYIGaMeta?.name ?? "company-dashboard-Index",
    path: IndexautfhMYIGaMeta?.path ?? "dashboard/Index",
    meta: IndexautfhMYIGaMeta || {},
    alias: IndexautfhMYIGaMeta?.alias || [],
    redirect: IndexautfhMYIGaMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/dashboard/Index.vue").then(m => m.default || m)
  },
  {
    name: faqAjsmFsUkE3Meta?.name ?? "company-help-faq",
    path: faqAjsmFsUkE3Meta?.path ?? "help/faq",
    meta: faqAjsmFsUkE3Meta || {},
    alias: faqAjsmFsUkE3Meta?.alias || [],
    redirect: faqAjsmFsUkE3Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/help/faq.vue").then(m => m.default || m)
  },
  {
    name: membery5FkljwxmeMeta?.name ?? "company-member",
    path: membery5FkljwxmeMeta?.path ?? "member",
    meta: membery5FkljwxmeMeta || {},
    alias: membery5FkljwxmeMeta?.alias || [],
    redirect: membery5FkljwxmeMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member.vue").then(m => m.default || m),
    children: [
  {
    name: _91code_93RR9a3FBdYGMeta?.name ?? "company-member-anniversary-code",
    path: _91code_93RR9a3FBdYGMeta?.path ?? "anniversary/:code()",
    meta: _91code_93RR9a3FBdYGMeta || {},
    alias: _91code_93RR9a3FBdYGMeta?.alias || [],
    redirect: _91code_93RR9a3FBdYGMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/anniversary/[code].vue").then(m => m.default || m)
  },
  {
    name: indexZCFVKKL41DMeta?.name ?? "company-member-anniversary",
    path: indexZCFVKKL41DMeta?.path ?? "anniversary",
    meta: indexZCFVKKL41DMeta || {},
    alias: indexZCFVKKL41DMeta?.alias || [],
    redirect: indexZCFVKKL41DMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/anniversary/index.vue").then(m => m.default || m)
  },
  {
    name: pdfJnaQfzhHHXMeta?.name ?? "company-member-anniversary-pdf",
    path: pdfJnaQfzhHHXMeta?.path ?? "anniversary/pdf",
    meta: pdfJnaQfzhHHXMeta || {},
    alias: pdfJnaQfzhHHXMeta?.alias || [],
    redirect: pdfJnaQfzhHHXMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/anniversary/pdf.vue").then(m => m.default || m)
  },
  {
    name: csv_update89wDgUHv4GMeta?.name ?? "company-member-csv_update",
    path: csv_update89wDgUHv4GMeta?.path ?? "csv_update",
    meta: csv_update89wDgUHv4GMeta || {},
    alias: csv_update89wDgUHv4GMeta?.alias || [],
    redirect: csv_update89wDgUHv4GMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/csv_update.vue").then(m => m.default || m)
  },
  {
    name: indexG2gYYlLiZZMeta?.name ?? "company-member-csv",
    path: indexG2gYYlLiZZMeta?.path ?? "csv",
    meta: indexG2gYYlLiZZMeta || {},
    alias: indexG2gYYlLiZZMeta?.alias || [],
    redirect: indexG2gYYlLiZZMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/csv/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93BHlIncAEU1Meta?.name ?? "company-member-detail-code",
    path: _91code_93BHlIncAEU1Meta?.path ?? "detail/:code()",
    meta: _91code_93BHlIncAEU1Meta || {},
    alias: _91code_93BHlIncAEU1Meta?.alias || [],
    redirect: _91code_93BHlIncAEU1Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/detail/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93ZnrwdoRwn6Meta?.name ?? "company-member-form-code",
    path: _91code_93ZnrwdoRwn6Meta?.path ?? "form/:code()",
    meta: _91code_93ZnrwdoRwn6Meta || {},
    alias: _91code_93ZnrwdoRwn6Meta?.alias || [],
    redirect: _91code_93ZnrwdoRwn6Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/form/[code].vue").then(m => m.default || m)
  },
  {
    name: indexPyAugmy376Meta?.name ?? "company-member-form",
    path: indexPyAugmy376Meta?.path ?? "form",
    meta: indexPyAugmy376Meta || {},
    alias: indexPyAugmy376Meta?.alias || [],
    redirect: indexPyAugmy376Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/form/index.vue").then(m => m.default || m)
  },
  {
    name: indexpgUEtC7jjJMeta?.name ?? "company-member-import",
    path: indexpgUEtC7jjJMeta?.path ?? "import",
    meta: indexpgUEtC7jjJMeta || {},
    alias: indexpgUEtC7jjJMeta?.alias || [],
    redirect: indexpgUEtC7jjJMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexsIrBj3LAmOMeta?.name ?? "company-member-Index",
    path: indexsIrBj3LAmOMeta?.path ?? "Index",
    meta: indexsIrBj3LAmOMeta || {},
    alias: indexsIrBj3LAmOMeta?.alias || [],
    redirect: indexsIrBj3LAmOMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/member/Index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: messageiw36QWi2UfMeta?.name ?? "company-message",
    path: messageiw36QWi2UfMeta?.path ?? "message",
    meta: messageiw36QWi2UfMeta || {},
    alias: messageiw36QWi2UfMeta?.alias || [],
    redirect: messageiw36QWi2UfMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/message.vue").then(m => m.default || m),
    children: [
  {
    name: _91code_93bBoU1BISh5Meta?.name ?? "company-message-detail-code",
    path: _91code_93bBoU1BISh5Meta?.path ?? "detail/:code()",
    meta: _91code_93bBoU1BISh5Meta || {},
    alias: _91code_93bBoU1BISh5Meta?.alias || [],
    redirect: _91code_93bBoU1BISh5Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/detail/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93GRJrkfdrJHMeta?.name ?? "company-message-form-code",
    path: _91code_93GRJrkfdrJHMeta?.path ?? "form/:code()",
    meta: _91code_93GRJrkfdrJHMeta || {},
    alias: _91code_93GRJrkfdrJHMeta?.alias || [],
    redirect: _91code_93GRJrkfdrJHMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/form/[code].vue").then(m => m.default || m)
  },
  {
    name: indexA3pRcxW0lqMeta?.name ?? "company-message-form",
    path: indexA3pRcxW0lqMeta?.path ?? "form",
    meta: indexA3pRcxW0lqMeta || {},
    alias: indexA3pRcxW0lqMeta?.alias || [],
    redirect: indexA3pRcxW0lqMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/form/index.vue").then(m => m.default || m)
  },
  {
    name: indexgjLs55yKLuMeta?.name ?? "company-message-Index",
    path: indexgjLs55yKLuMeta?.path ?? "Index",
    meta: indexgjLs55yKLuMeta || {},
    alias: indexgjLs55yKLuMeta?.alias || [],
    redirect: indexgjLs55yKLuMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/message/Index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: notificationuYVNsOKyTXMeta?.name ?? "company-notification",
    path: notificationuYVNsOKyTXMeta?.path ?? "notification",
    meta: notificationuYVNsOKyTXMeta || {},
    alias: notificationuYVNsOKyTXMeta?.alias || [],
    redirect: notificationuYVNsOKyTXMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/notification.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_935lwFJFR3dzMeta?.name ?? "company-notification-id",
    path: _91id_935lwFJFR3dzMeta?.path ?? ":id()",
    meta: _91id_935lwFJFR3dzMeta || {},
    alias: _91id_935lwFJFR3dzMeta?.alias || [],
    redirect: _91id_935lwFJFR3dzMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/notification/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexifEk61QhenMeta?.name ?? "company-report-Index",
    path: indexifEk61QhenMeta?.path ?? "report/Index",
    meta: indexifEk61QhenMeta || {},
    alias: indexifEk61QhenMeta?.alias || [],
    redirect: indexifEk61QhenMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/report/Index/index.vue").then(m => m.default || m)
  },
  {
    name: accountaSJQpJZePRMeta?.name ?? "company-setting-account",
    path: accountaSJQpJZePRMeta?.path ?? "setting/account",
    meta: accountaSJQpJZePRMeta || {},
    alias: accountaSJQpJZePRMeta?.alias || [],
    redirect: accountaSJQpJZePRMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/account.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93nO6BwWrYljMeta?.name ?? "company-setting-account-department-detail-id",
    path: _91id_93nO6BwWrYljMeta?.path ?? "department/detail/:id()",
    meta: _91id_93nO6BwWrYljMeta || {},
    alias: _91id_93nO6BwWrYljMeta?.alias || [],
    redirect: _91id_93nO6BwWrYljMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/account/department/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_930UPpoa8NeDMeta?.name ?? "company-setting-account-department-form-id",
    path: _91id_930UPpoa8NeDMeta?.path ?? "department/form/:id()",
    meta: _91id_930UPpoa8NeDMeta || {},
    alias: _91id_930UPpoa8NeDMeta?.alias || [],
    redirect: _91id_930UPpoa8NeDMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/account/department/form/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: enqVK6CCW0TOfMeta?.name ?? undefined,
    path: enqVK6CCW0TOfMeta?.path ?? "setting/enq",
    meta: enqVK6CCW0TOfMeta || {},
    alias: enqVK6CCW0TOfMeta?.alias || [],
    redirect: enqVK6CCW0TOfMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq.vue").then(m => m.default || m),
    children: [
  {
    name: annual30bCs8Ie4nMeta?.name ?? "company-setting-enq-annual",
    path: annual30bCs8Ie4nMeta?.path ?? "annual",
    meta: annual30bCs8Ie4nMeta || {},
    alias: annual30bCs8Ie4nMeta?.alias || [],
    redirect: annual30bCs8Ie4nMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/annual.vue").then(m => m.default || m)
  },
  {
    name: indexqBtWuMQPSrMeta?.name ?? "company-setting-enq",
    path: indexqBtWuMQPSrMeta?.path ?? "",
    meta: indexqBtWuMQPSrMeta || {},
    alias: indexqBtWuMQPSrMeta?.alias || [],
    redirect: indexqBtWuMQPSrMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/index.vue").then(m => m.default || m)
  },
  {
    name: indexnxD8RgjJYgMeta?.name ?? "company-setting-enq-monthly",
    path: indexnxD8RgjJYgMeta?.path ?? "monthly",
    meta: indexnxD8RgjJYgMeta || {},
    alias: indexnxD8RgjJYgMeta?.alias || [],
    redirect: indexnxD8RgjJYgMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/monthly/index.vue").then(m => m.default || m)
  },
  {
    name: thanks862H0k1pegMeta?.name ?? "company-setting-enq-monthly-thanks",
    path: thanks862H0k1pegMeta?.path ?? "monthly/thanks",
    meta: thanks862H0k1pegMeta || {},
    alias: thanks862H0k1pegMeta?.alias || [],
    redirect: thanks862H0k1pegMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/monthly/thanks.vue").then(m => m.default || m)
  },
  {
    name: indexILGAhPY1t8Meta?.name ?? "company-setting-enq-notification",
    path: indexILGAhPY1t8Meta?.path ?? "notification",
    meta: indexILGAhPY1t8Meta || {},
    alias: indexILGAhPY1t8Meta?.alias || [],
    redirect: indexILGAhPY1t8Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/index.vue").then(m => m.default || m)
  },
  {
    name: lineYS1T2RScFKMeta?.name ?? "company-setting-enq-notification-line",
    path: lineYS1T2RScFKMeta?.path ?? "notification/line",
    meta: lineYS1T2RScFKMeta || {},
    alias: lineYS1T2RScFKMeta?.alias || [],
    redirect: lineYS1T2RScFKMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/line.vue").then(m => m.default || m)
  },
  {
    name: slackwaZznALpPYMeta?.name ?? "company-setting-enq-notification-slack",
    path: slackwaZznALpPYMeta?.path ?? "notification/slack",
    meta: slackwaZznALpPYMeta || {},
    alias: slackwaZznALpPYMeta?.alias || [],
    redirect: slackwaZznALpPYMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/slack.vue").then(m => m.default || m)
  },
  {
    name: smsX9ldShW8WhMeta?.name ?? "company-setting-enq-notification-sms",
    path: smsX9ldShW8WhMeta?.path ?? "notification/sms",
    meta: smsX9ldShW8WhMeta || {},
    alias: smsX9ldShW8WhMeta?.alias || [],
    redirect: smsX9ldShW8WhMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/enq/notification/sms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: importxaU0vixy4iMeta?.name ?? "company-setting-import",
    path: importxaU0vixy4iMeta?.path ?? "setting/import",
    meta: importxaU0vixy4iMeta || {},
    alias: importxaU0vixy4iMeta?.alias || [],
    redirect: importxaU0vixy4iMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/import.vue").then(m => m.default || m)
  },
  {
    name: _91code_93dHvCmiwDuuMeta?.name ?? "company-setting-message-code",
    path: _91code_93dHvCmiwDuuMeta?.path ?? "setting/message/:code()",
    meta: _91code_93dHvCmiwDuuMeta || {},
    alias: _91code_93dHvCmiwDuuMeta?.alias || [],
    redirect: _91code_93dHvCmiwDuuMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/message/[code].vue").then(m => m.default || m)
  },
  {
    name: create3LWRlhQhi5Meta?.name ?? "company-setting-message-create",
    path: create3LWRlhQhi5Meta?.path ?? "setting/message/create",
    meta: create3LWRlhQhi5Meta || {},
    alias: create3LWRlhQhi5Meta?.alias || [],
    redirect: create3LWRlhQhi5Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/message/create.vue").then(m => m.default || m)
  },
  {
    name: indexUGJbpY8k0GMeta?.name ?? "company-setting-message",
    path: indexUGJbpY8k0GMeta?.path ?? "setting/message",
    meta: indexUGJbpY8k0GMeta || {},
    alias: indexUGJbpY8k0GMeta?.alias || [],
    redirect: indexUGJbpY8k0GMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/message/index.vue").then(m => m.default || m)
  },
  {
    name: securityZYqJigXLJvMeta?.name ?? "company-setting-security",
    path: securityZYqJigXLJvMeta?.path ?? "setting/security",
    meta: securityZYqJigXLJvMeta || {},
    alias: securityZYqJigXLJvMeta?.alias || [],
    redirect: securityZYqJigXLJvMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/security.vue").then(m => m.default || m)
  },
  {
    name: talent_importYKDsfK9WZAMeta?.name ?? "company-setting-talent_import",
    path: talent_importYKDsfK9WZAMeta?.path ?? "setting/talent_import",
    meta: talent_importYKDsfK9WZAMeta || {},
    alias: talent_importYKDsfK9WZAMeta?.alias || [],
    redirect: talent_importYKDsfK9WZAMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/setting/talent_import.vue").then(m => m.default || m)
  },
  {
    name: _91id_93saX7qA8vXdMeta?.name ?? "company-temp_member-id",
    path: _91id_93saX7qA8vXdMeta?.path ?? "temp_member/:id()",
    meta: _91id_93saX7qA8vXdMeta || {},
    alias: _91id_93saX7qA8vXdMeta?.alias || [],
    redirect: _91id_93saX7qA8vXdMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/temp_member/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWEeqKjc3aqMeta?.name ?? "company-test-Index",
    path: indexWEeqKjc3aqMeta?.path ?? "test/Index",
    meta: indexWEeqKjc3aqMeta || {},
    alias: indexWEeqKjc3aqMeta?.alias || [],
    redirect: indexWEeqKjc3aqMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/test/Index/index.vue").then(m => m.default || m)
  },
  {
    name: mail_companyVhnvzz69LkMeta?.name ?? "company-test-mail_company",
    path: mail_companyVhnvzz69LkMeta?.path ?? "test/mail_company",
    meta: mail_companyVhnvzz69LkMeta || {},
    alias: mail_companyVhnvzz69LkMeta?.alias || [],
    redirect: mail_companyVhnvzz69LkMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/test/mail_company.vue").then(m => m.default || m)
  },
  {
    name: mail_personal0o5In5a8gEMeta?.name ?? "company-test-mail_personal",
    path: mail_personal0o5In5a8gEMeta?.path ?? "test/mail_personal",
    meta: mail_personal0o5In5a8gEMeta || {},
    alias: mail_personal0o5In5a8gEMeta?.alias || [],
    redirect: mail_personal0o5In5a8gEMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/test/mail_personal.vue").then(m => m.default || m)
  },
  {
    name: tutorialCUZA4lLVfcMeta?.name ?? "company-tutorial",
    path: tutorialCUZA4lLVfcMeta?.path ?? "tutorial",
    meta: tutorialCUZA4lLVfcMeta || {},
    alias: tutorialCUZA4lLVfcMeta?.alias || [],
    redirect: tutorialCUZA4lLVfcMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/company/tutorial.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKHwbvcZbzeMeta?.name ?? "engage-callback",
    path: indexKHwbvcZbzeMeta?.path ?? "/engage/callback",
    meta: indexKHwbvcZbzeMeta || {},
    alias: indexKHwbvcZbzeMeta?.alias || [],
    redirect: indexKHwbvcZbzeMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/engage/callback/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93THDMiu6T4pMeta?.name ?? "features-id",
    path: _91id_93THDMiu6T4pMeta?.path ?? "/features/:id()",
    meta: _91id_93THDMiu6T4pMeta || {},
    alias: _91id_93THDMiu6T4pMeta?.alias || [],
    redirect: _91id_93THDMiu6T4pMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/features/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvoTDIqjsojMeta?.name ?? "features",
    path: indexvoTDIqjsojMeta?.path ?? "/features",
    meta: indexvoTDIqjsojMeta || {},
    alias: indexvoTDIqjsojMeta?.alias || [],
    redirect: indexvoTDIqjsojMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: index8GONOkqvOlMeta?.name ?? "index",
    path: index8GONOkqvOlMeta?.path ?? "/",
    meta: index8GONOkqvOlMeta || {},
    alias: index8GONOkqvOlMeta?.alias || [],
    redirect: index8GONOkqvOlMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOWV1KE7eKEMeta?.name ?? "introduction",
    path: indexOWV1KE7eKEMeta?.path ?? "/introduction",
    meta: indexOWV1KE7eKEMeta || {},
    alias: indexOWV1KE7eKEMeta?.alias || [],
    redirect: indexOWV1KE7eKEMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/introduction/index.vue").then(m => m.default || m)
  },
  {
    name: _91date_93vLEHuw4Q4GMeta?.name ?? "news-date",
    path: _91date_93vLEHuw4Q4GMeta?.path ?? "/news/:date()",
    meta: _91date_93vLEHuw4Q4GMeta || {},
    alias: _91date_93vLEHuw4Q4GMeta?.alias || [],
    redirect: _91date_93vLEHuw4Q4GMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/news/[date].vue").then(m => m.default || m)
  },
  {
    name: draftbmCNsDRChMMeta?.name ?? "news-draft",
    path: draftbmCNsDRChMMeta?.path ?? "/news/draft",
    meta: draftbmCNsDRChMMeta || {},
    alias: draftbmCNsDRChMMeta?.alias || [],
    redirect: draftbmCNsDRChMMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/news/draft.vue").then(m => m.default || m)
  },
  {
    name: indexNgwdSS2Cp0Meta?.name ?? "news",
    path: indexNgwdSS2Cp0Meta?.path ?? "/news",
    meta: indexNgwdSS2Cp0Meta || {},
    alias: indexNgwdSS2Cp0Meta?.alias || [],
    redirect: indexNgwdSS2Cp0Meta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexM3xYQ7KvEiMeta?.name ?? "performance",
    path: indexM3xYQ7KvEiMeta?.path ?? "/performance",
    meta: indexM3xYQ7KvEiMeta || {},
    alias: indexM3xYQ7KvEiMeta?.alias || [],
    redirect: indexM3xYQ7KvEiMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/performance/index.vue").then(m => m.default || m)
  },
  {
    name: indexBIs9mf7EvVMeta?.name ?? "terms",
    path: indexBIs9mf7EvVMeta?.path ?? "/terms",
    meta: indexBIs9mf7EvVMeta || {},
    alias: indexBIs9mf7EvVMeta?.alias || [],
    redirect: indexBIs9mf7EvVMeta?.redirect,
    component: () => import("/home/runner/work/onboard/onboard/Code/app/src/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZZiHwHNnbMeta?.name ?? "newspage",
    path: indexmZZiHwHNnbMeta?.path ?? "/news/page/:p",
    meta: indexmZZiHwHNnbMeta || {},
    alias: indexmZZiHwHNnbMeta?.alias || [],
    redirect: indexmZZiHwHNnbMeta?.redirect,
    component: () => import("~/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexlrxaCla384Meta?.name ?? "casepage",
    path: indexlrxaCla384Meta?.path ?? "/case/page/:p",
    meta: indexlrxaCla384Meta || {},
    alias: indexlrxaCla384Meta?.alias || [],
    redirect: indexlrxaCla384Meta?.redirect,
    component: () => import("~/pages/case/index.vue").then(m => m.default || m)
  }
]