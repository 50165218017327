import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MessageTemplate } from '~/types/messageTemplate'

export type Methods = DefineMethods<{
  put: {
    reqBody: MessageTemplate
    resBody: {}
  }
  delete: {
    resBody: {}
  }
  get: {
    resBody: MessageTemplate
  }
}>

export default mockMethods<Methods>({
  put: () => ({
    status: 200,
    resBody: {},
  }),
  delete: () => ({
    status: 200,
    resBody: {},
  }),
  get: ()=> ({
    status: 200,
    resBody: {
      id: 1,
      code: '630eff1b380505a67570dff952ce4ad720210104151057',
      name: '研修連絡3ヶ月',
      subject: 'メッセージをご確認ください',
      context:
        '#名前#さん \nこんにちは。\n#差出人組織#さんの#差出人名前#です \n',
      note: '2020年 新卒採用',
    },
  })
})
