import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MemberSearchItem, MemberSearchQuery } from '~/types/member'

export type Methods = DefineMethods<{
  get: {
    query: MemberSearchQuery
    resBody: {
      result: {
        members: MemberSearchItem[]
        count: number
        start_date: string
      }
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        members: [
          {
            id: 1,
            code: '8540942181',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user1.png',
            date_contracted: '2020-04-01',
            condition_type: 1,
            anniv_sent: 1,
            anniv_answer_completed: 1,
            anniv_senior_answer_completed: 1,
            anniv_answered_senior_id: 1,
            lastname: '高橋',
            firstname: '理沙',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187589,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187590,
                month: '2021-07-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187591,
                month: '2021-08-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187592,
                month: '2021-09-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 2,
                question_tag: true,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text:
              '今月も忙しすぎて趣味の時間が取れなかった。とても忙しく大変な毎日を繰り返している。良くない状態です',
            answer_text_length: 50,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上野',
            senior_firstname: '勝',
            hr_user_id: 1,
            hr_user_name: '今中太郎',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 1,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 1,
            sending_at: '2021-03-01',
            talent_status: 1,
            talent_member_id: 11,
            lastname_kana: 'タカハシ',
            firstname_kana: 'リサ',
          },
          {
            id: 2,
            code: '9993434304',
            department: {
              id: 2,
              full_path: 'デモ株式会社 > 人事部',
              short_name: '人事部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user2.png',
            date_contracted: '2020-04-01',
            condition_type: 3,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '宮崎',
            firstname: '裕美',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 3,
                question_tag: true,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '入社したばかりです。頑張ります。',
            answer_text_length: 10,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上記',
            senior_firstname: '勝',
            hr_user_id: 1,
            hr_user_name: '吉岡建造',
            interview_status: 1,
            interview_due_date: '2020-12-31',
            importance: 3,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 1,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 1,
            sending_at: '2021-02-01',
            talent_status: 1,
            talent_member_id: 10,
            lastname_kana: 'ミヤザキ',
            firstname_kana: 'ユミ',
          },
          {
            id: 3,
            code: '6819148994',
            department: {
              id: 3,
              full_path: 'デモ株式会社 > 開発部',
              short_name: '開発部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user3.png',
            date_contracted: '2020-04-01',
            condition_type: 2,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '西田',
            firstname: '聡',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 99,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 1,
                question_tag: true,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: 'すこしつらい状況です。頑張ってまいります。',
            answer_text_length: 21,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '鎌田',
            senior_firstname: '勝',
            hr_user_id: 1,
            hr_user_name: '近藤辰巳',
            interview_status: 2,
            interview_due_date: '2020-12-31',
            importance: 2,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: true,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 9,
            lastname_kana: 'ニシダ',
            firstname_kana: 'サトシ',
          },
          {
            id: 4,
            code: '1596494267',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user4.png',
            date_contracted: '2020-04-01',
            condition_type: 2,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '川崎',
            firstname: '亜紀',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 1,
                question_tag: true,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '仕事が楽しいです。特に問題ないです。',
            answer_text_length: 18,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '河野',
            senior_firstname: '美奈',
            hr_user_id: 1,
            hr_user_name: '小田蒼汰',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 1,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 1,
            talent_member_id: 8,
            lastname_kana: 'カワサキ',
            firstname_kana: 'アキ',
          },
          {
            id: 5,
            code: '7140965380',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user5.png',
            date_contracted: '2020-04-01',
            condition_type: 2,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '渡邊',
            firstname: '洋平',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 3,
                question_tag: true,
                need_follow_tag: true,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '河野',
            senior_firstname: '美奈',
            hr_user_id: 1,
            hr_user_name: '宮崎勤',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 1,
            talent_member_id: 7,
            lastname_kana: 'ワタナベ',
            firstname_kana: 'ヨウヘイ',
          },
          {
            id: 6,
            code: '8515856096',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user6.png',
            date_contracted: '2020-04-01',
            condition_type: 2,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '星野',
            firstname: '美希',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: true,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '',
            senior_id: 1,
            senior_lastname: '上林',
            senior_firstname: '叶',
            hr_user_id: 1,
            hr_user_name: '浜崎聖人',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 6,
            lastname_kana: 'ホシノ',
            firstname_kana: 'ミキ',
          },
          {
            id: 7,
            code: '7522851289',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user7.png',
            date_contracted: '2020-04-01',
            condition_type: 3,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '竹内',
            firstname: '浩一',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 99,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上林',
            senior_firstname: '叶',
            hr_user_id: 1,
            hr_user_name: '立花さやか',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 5,
            lastname_kana: 'タケウチ',
            firstname_kana: 'コウイチ',
          },
          {
            id: 8,
            code: '6467494799',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user8.png',
            date_contracted: '2020-04-01',
            condition_type: 1,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '梅林',
            firstname: '文哉',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上田',
            senior_firstname: '紳助',
            hr_user_id: 1,
            hr_user_name: '清水健二',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 4,
            lastname_kana: 'ウメバヤシ',
            firstname_kana: 'フミヤ',
          },
          {
            id: 9,
            code: '6964704721',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user9.png',
            date_contracted: '2020-04-01',
            condition_type: 1,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '遠藤',
            firstname: 'ひかり',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上田',
            senior_firstname: '紳助',
            hr_user_id: 1,
            hr_user_name: '清水健二',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 3,
            lastname_kana: 'エンドウ',
            firstname_kana: 'ヒカリ',
          },
          {
            id: 10,
            code: '6265434524',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user12.png',
            date_contracted: '2020-04-01',
            condition_type: 1,
            anniv_sent: 1,
            anniv_answer_completed: 1,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '宇野',
            firstname: '達朗',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 99,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 99,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上杉',
            senior_firstname: '佳代子',
            hr_user_id: 1,
            hr_user_name: '清水健二',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 2,
            lastname_kana: 'ウノ',
            firstname_kana: 'タツロウ',
          },
          {
            id: 11,
            code: '2489945860',
            department: {
              id: 1,
              full_path: 'デモ株式会社 > 新規事業部',
              short_name: '新規事業部',
            },
            email: 'test@test.com',
            image_uri: '/img/mock/user11.png',
            date_contracted: '2020-04-01',
            condition_type: 1,
            anniv_sent: 0,
            anniv_answer_completed: 0,
            anniv_senior_answer_completed: 0,
            anniv_answered_senior_id: null,
            lastname: '鈴木',
            firstname: '陽介',
            answers: [
              {
                id: 187582,
                month: '2020-12-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187583,
                month: '2021-01-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187584,
                month: '2021-02-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187585,
                month: '2021-03-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187586,
                month: '2021-04-01',
                condition_type: 2,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187587,
                month: '2021-05-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-06-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-07-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-08-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187588,
                month: '2021-09-01',
                condition_type: 3,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187593,
                month: '2021-10-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
              {
                id: 187594,
                month: '2021-11-01',
                condition_type: 1,
                question_tag: false,
                need_follow_tag: false,
                waiting_emotion_answer: false,
              },
            ],
            answer_text: '',
            answer_text_length: 0,
            answered_at: '2020-12-01',
            senior_id: 1,
            senior_lastname: '上杉',
            senior_firstname: '佳代子',
            hr_user_id: 1,
            hr_user_name: '清水健二',
            interview_status: 0,
            interview_due_date: '2020-12-31',
            importance: 1,
            employing_type: 1,
            working_status: 1,
            gender: 1,
            birth_year: 2000,
            birth_month: 1,
            birth_day: 1,
            initial_evaluation: 1,
            is_newbie: 1,
            progress_status: 2,
            age: 21,
            has_past_1_year: false,
            is_stopped_send_questions_mail: 0,
            message_read: 0,
            sending_at: '2021-01-01',
            talent_status: 0,
            talent_member_id: 1,
            lastname_kana: 'スズキ',
            firstname_kana: 'ヨウスケ',
          },
        ],
        start_date: '2020-12-01',
        count: 500,
      },
    },
  }),
})
