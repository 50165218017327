import { ref, inject } from 'vue'

export type FlashMessageTypes = 'success' | 'fail'

export type FlashMessageOption = {
  autoHide?: boolean
  hideTime?: number
}

export type AppFlashMessageAttrs = {
  isVisible: boolean
  type: FlashMessageTypes
  message: string
}

export type UseFlashMessage = {
  flashMessageAttrs: AppFlashMessageAttrs
  flashMessageOption: FlashMessageOption
  showFlashMessage: (
    type: FlashMessageTypes,
    message: string,
    opt?: FlashMessageOption,
  ) => void
  clearFlashMessage: () => void
}

const DefaultFlashMessageOption: Required<FlashMessageOption> = {
  autoHide: true,
  hideTime: 5000,
}

export const appFlashMessageKey = Symbol()

export default function useFlashMessage(): UseFlashMessage {
  const provided = inject<UseFlashMessage>(appFlashMessageKey)

  if (provided === undefined) {
    throw Error('Message Not Provided')
  }

  return provided
}

export const initializeFlashMessage = (): UseFlashMessage => {
  const flashMessageAttrs = ref<AppFlashMessageAttrs>({
    isVisible: false,
    type: 'success',
    message: '',
  })
  const flashMessageOption = ref<FlashMessageOption>(DefaultFlashMessageOption)
  let timer: ReturnType<typeof setTimeout> | undefined

  const clearFlashMessage = (): void => {
    flashMessageAttrs.value.isVisible = false
    clearTimeout(timer)
    timer = undefined
  }

  function showFlashMessage(
    type: FlashMessageTypes,
    message: string,
    opt?: FlashMessageOption,
  ): void {
    flashMessageAttrs.value.type = type
    flashMessageAttrs.value.message = message
    flashMessageAttrs.value.isVisible = true
    flashMessageOption.value = { ...DefaultFlashMessageOption, ...opt }

    if (flashMessageOption.value.autoHide) {
      timer = setTimeout(() => {
        clearFlashMessage()
      }, flashMessageOption.value.hideTime)
    }
  }

  return {
    flashMessageAttrs: flashMessageAttrs.value,
    flashMessageOption: flashMessageOption.value,
    showFlashMessage,
    clearFlashMessage,
  }
}
