import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      member_id: number
      talent_member_id: number
    }
    resBody: Res
  }
}>

type Res = {
  success: boolean
  status: number
  response: string
  message: string
}

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: {
      success: true,
      status: 200,
      response: '',
      message: '',
    },
  }),
})
