import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp
  Sentry.init({
    app: vueApp,
    dsn: 'https://48f5a525f92047ddb8b660b37e6ae98a@o81851.ingest.us.sentry.io/5708621',
    enabled: config.public.SENTRY_DISABLE === 'false',
    environment: process.env.NODE_ENV,
  })
})
