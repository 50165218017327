<template>
  <div
    class="app-loading"
    :class="{ '-visible': appLoadingAttrs.isVisible }"
  >
    <div class="sk-fading-circle">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UseLoading } from '~/composables/useLoading'
import { appLoadingKey } from '~/composables/useLoading'
import { useNuxtApp } from '#app'

const provided = inject<UseLoading>(appLoadingKey)
const nuxtApp = useNuxtApp()

if (provided === undefined) {
  throw Error('AppLoading Not Provided')
}

const { appLoadingAttrs, start, finish, isNavigating } = provided

const safeFinish = (): void => {
  if (!isNavigating.value) {
    finish()
  }
}

nuxtApp.hook('page:start', start)
nuxtApp.hook('page:finish', safeFinish)
nuxtApp.hook('vue:error', finish)

onBeforeMount(finish)
</script>

<style lang="scss" scoped>
.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-loading;
  display: none;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.9);

  &.-visible {
    display: block;
  }

  .sk-fading-circle {
    position: relative;
    width: 40px;
    height: 40px;
    margin: calc((100vh - 40px) / 2) auto;
  }

  .sk-fading-circle .sk-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .sk-fading-circle .sk-circle::before {
    display: block;
    width: 15%;
    height: 15%;
    margin: 0 auto;
    content: '';
    background-color: $dark-blue-color;
    border-radius: 100%;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }

  .sk-fading-circle .sk-circle2 {
    transform: rotate(30deg);
  }

  .sk-fading-circle .sk-circle3 {
    transform: rotate(60deg);
  }

  .sk-fading-circle .sk-circle4 {
    transform: rotate(90deg);
  }

  .sk-fading-circle .sk-circle5 {
    transform: rotate(120deg);
  }

  .sk-fading-circle .sk-circle6 {
    transform: rotate(150deg);
  }

  .sk-fading-circle .sk-circle7 {
    transform: rotate(180deg);
  }

  .sk-fading-circle .sk-circle8 {
    transform: rotate(210deg);
  }

  .sk-fading-circle .sk-circle9 {
    transform: rotate(240deg);
  }

  .sk-fading-circle .sk-circle10 {
    transform: rotate(270deg);
  }

  .sk-fading-circle .sk-circle11 {
    transform: rotate(300deg);
  }

  .sk-fading-circle .sk-circle12 {
    transform: rotate(330deg);
  }

  .sk-fading-circle .sk-circle2::before {
    animation-delay: -1.1s;
  }

  .sk-fading-circle .sk-circle3::before {
    animation-delay: -1s;
  }

  .sk-fading-circle .sk-circle4::before {
    animation-delay: -0.9s;
  }

  .sk-fading-circle .sk-circle5::before {
    animation-delay: -0.8s;
  }

  .sk-fading-circle .sk-circle6::before {
    animation-delay: -0.7s;
  }

  .sk-fading-circle .sk-circle7::before {
    animation-delay: -0.6s;
  }

  .sk-fading-circle .sk-circle8::before {
    animation-delay: -0.5s;
  }

  .sk-fading-circle .sk-circle9::before {
    animation-delay: -0.4s;
  }

  .sk-fading-circle .sk-circle10::before {
    animation-delay: -0.3s;
  }

  .sk-fading-circle .sk-circle11::before {
    animation-delay: -0.2s;
  }

  .sk-fading-circle .sk-circle12::before {
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }
}
</style>
