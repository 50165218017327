import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Member } from '~/types/member'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      members: Member[]
      count: number
    }
    query: {
      limit: number
      'delivery_period[]': number
      'delivery_settings[]': number
      'working_status[]': number
    }
  }
}>

const members = [
  {
    id: 1,
    company_id: 1,
    code: '5772441978',
    lastname: 'エン',
    firstname: '太郎',
    lastname_kana: 'エン',
    firstname_kana: 'タロウ',
    employee_code: '100001',
    employing_type: 1,
    working_status: 1,
    date_resigned: '2020-09-17',
    resign_reason: '7',
    resign_reason_text: '転職のため',
    gender: 1,
    birth_year: 2010,
    birth_month: 10,
    birth_day: 2,
    mobile_tel_number: '04077779999',
    email: 'test@en-japan.com',
    delivery_date: '2019-08-01',
    profession: '2017-11-01',
    senior_id: 1,
    initial_evaluation: '1',
    is_newbie: 1,
    entry_reason: 'がんばってた',
    recruiter: '高橋花子',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    route_employed: '1',
    route_employed_detail: '',
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    is_stopped_send_anniv_mail: 0,
    is_stopped_send_questions_mail: 0,
    mail_language: 0,
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    anniv_answered_senior_id: null,
    anniv_sent: 1,
    answer_text: '',
    answered_at: null,
    condition_type: 1,
    date_contracted: '2017-11-01',
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    image_uri: '/img/sample-user.png',
    line_works_send_success_flag: 0,
    line_works_id: 1,
    line_user_id: 1,
    line_status: 1 as 0 | 1 | 2 | 3,
    sms_status: 0 as 0 | 1 | 2 | 3,
    using_slack: 1 as 0 | 1 | 2,
    app_status: 0 as 0 | 1 | 2,
    answers: [{ id: 187582, month: '2020-12-01', condition_type: 1 }],
    importance: 1,
    interview_status: 1,
    interview_due_date: '2020/12/31',
    hr_user_id: 23,
    talent_status: 0 as  0 | 1,
    message_read: 0 as 0 | 1 | 2 | 3,
    has_image: 1,
    progress_status: 1,
    date_quit: '',
    is_deleted: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    talent_member_id: '',
    anniv_renewal_sent_date: '',
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
  },
  {
    id: 2,
    code: '9993434304',
    email: 'test2@en-japan.com',
    lastname: '宮崎',
    firstname: '裕美',
    lastname_kana: 'スズキ',
    firstname_kana: 'イチロウ',
    company_id: 1,
    senior_id: 1,
    employee_code: '100001',
    date_contracted: '2017-11-01',
    date_resigned: '2020-09-17',
    resign_reason: '7',
    resign_reason_text: '転職のため',
    initial_evaluation: '1',
    has_image: 1,
    image_uri: '/img/sample-user.png',
    condition_type: 1,
    progress_status: 1,
    working_status: 1,
    interview_status: 1,
    importance: 1,
    interview_due_date: '2020/12/31',
    gender: 1,
    birth_year: 2010,
    birth_month: 10,
    birth_day: 2,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    profession: '2017-11-01',
    hr_user_id: 23,
    recruiter: '高橋花子',
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    entry_reason: 'がんばってた',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    date_quit: '',
    is_newbie: 1,
    is_deleted: 0,
    is_stopped_send_anniv_mail: 0,
    is_stopped_send_questions_mail: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    employing_type: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    mobile_tel_number: '04077779999',
    line_user_id: 1,
    line_status: 1 as 0 | 1 | 2 | 3,
    sms_status: 0 as 0 | 1 | 2 | 3,
    using_slack: 1 as 0 | 1 | 2,
    app_status: 0 as 0 | 1 | 2,
    talent_status: 0 as  0 | 1,
    message_read: 0 as 0 | 1 | 2 | 3,
    talent_member_id: '',
    line_works_id: 1,
    line_works_send_success_flag: 0,
    route_employed: '1',
    route_employed_detail: '',
    anniv_sent: 1,
    anniv_renewal_sent_date: '',
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    delivery_date: '2019-08-01',
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    mail_language: 0,
    anniv_answered_senior_id: null,
    answer_text: '',
    answered_at: null,
    answers: [{ id: 187582, month: '2020-12-01', condition_type: 1 }],
  },
  {
    id: 3,
    code: '9995434304',
    email: 'test3@en-japan.com',
    lastname: '鈴木',
    firstname: '陽介',
    lastname_kana: 'スズキ',
    firstname_kana: 'イチロウ',
    company_id: 1,
    senior_id: 1,
    employee_code: '100001',
    date_contracted: '2017-11-01',
    date_resigned: '2020-09-17',
    resign_reason: '7',
    resign_reason_text: '転職のため',
    initial_evaluation: '1',
    has_image: 1,
    image_uri: '/img/sample-user.png',
    condition_type: 1,
    progress_status: 1,
    working_status: 1,
    interview_status: 1,
    importance: 1,
    interview_due_date: '2020/12/31',
    gender: 1,
    birth_year: 2010,
    birth_month: 10,
    birth_day: 2,
    department: {
      id: 1,
      full_path: 'デモ株式会社 > 新規事業部',
      short_name: '新規事業部',
    },
    talent_status: 0 as  0 | 1,
    message_read: 0 as 0 | 1 | 2 | 3,
    line_status: 1 as 0 | 1 | 2 | 3,
    sms_status: 0 as 0 | 1 | 2 | 3,
    using_slack: 1 as 0 | 1 | 2,
    app_status: 0 as 0 | 1 | 2,
    profession: '2017-11-01',
    hr_user_id: 23,
    recruiter: '高橋花子',
    ex_company: 'テスト',
    ex_profession: 'ディレクター',
    entry_reason: 'がんばってた',
    other_info: 'テスト',
    other_info2: 'テスト',
    other_info3: 'テスト',
    date_quit: '',
    is_newbie: 1,
    is_deleted: 0,
    is_stopped_send_anniv_mail: 0,
    is_stopped_send_questions_mail: 0,
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    initial_evaluated_point: '1',
    initial_worry_point: '1',
    job_change_count: 5,
    working_years: 6,
    graduation_type: 1,
    employing_type: 1,
    slack_email: 'test@en-japan.com',
    slack_user_id: 1,
    bounce_count: 2,
    mobile_tel_number: '04077779999',
    line_user_id: 1,
    talent_member_id: '',
    line_works_id: 1,
    line_works_send_success_flag: 0,
    route_employed: '1',
    route_employed_detail: '',
    anniv_sent: 1,
    anniv_renewal_sent_date: '',
    anniv_answer_completed: 0,
    anniv_senior_answer_completed: 0,
    delivery_date: '2019-08-01',
    bounce_reason: '',
    app_member_token: '',
    push_enabled: 1,
    mail_language: 0,
    anniv_answered_senior_id: null,
    answer_text: '',
    answered_at: null,
    answers: [{ id: 187582, month: '2020-12-01', condition_type: 1 }],
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      members,
      count: 500,
    },
  }),
})
