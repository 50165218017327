import type { DefineMethods } from 'aspida'
import type { Notification } from '~/types/notification'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Notification
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      id: 1,
      title: '【有料申込スケジュール】メンバーを追加したい企業様へのお知らせ',
      body: '来月よりアンケート配信を開始したいメンバー枠のお申し込みを承ります。\n～ 料金\n1人あたり10,000円(税抜)/最大12ヶ月\n※初回配信日は10/1以降がお選びいただけます。\n',
      date_published: '2018/12/07',
      is_latest: true,
      is_draft: false,
      created_at: '2018/12/07',
      updated_at: '2018/12/07',
    },
  }),
})
