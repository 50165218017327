import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      login_id: string
      login_pw: string
      site_id: string
    }
    resBody: {
      success: boolean
    }
  }
  delete: {
    query: {
      id: number
    }
    resBody: {
      success: boolean
    }
  }
}>

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: {
      success: true,
    },
  }),
  delete: () => ({
    status: 200,
    resBody: {
      success: true,
    },
  }),
})
