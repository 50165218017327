import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Category } from '~/components/pages/company/setting/security/SecurityLog.vue'


export type Methods = DefineMethods<{
  get: {
    resBody: Category[]
  }
}>

const categories = [
  { name: 'ホーム' },
  { name: 'メンバー一覧' },
  { name: 'お知らせ' },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: categories,
  }),
})
