/**
 * @file Enable vue-i18n
 * All words must be defined in '@/locales'.
 */
import { createI18n } from 'vue-i18n'
import ja from '~/locales/ja.json'

export default defineNuxtPlugin(({ vueApp }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'ja',
    messages: {
      ja: {
        ...ja,
      },
    },
  })

  vueApp.use(i18n)

  return {
    provide: {
      i18n,
    },
  }
})
