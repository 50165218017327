import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MessageTemplate } from '~/types/messageTemplate'

export type Methods = DefineMethods<{
  get: {
    resBody: MessageTemplate[]
  }
  post: {
    reqBody: MessageTemplate
    resBody: {
      data: MessageTemplate,
      input: MessageTemplate,
      success: boolean,
    }
  }
}>

const message_template = [
  {
    id: 1,
    code: '630eff1b380505a67570dff952ce4ad720210104151057',
    name: '研修連絡3ヶ月',
    subject: 'メッセージをご確認ください',
    context: '#名前#さん \nこんにちは。\n#差出人組織#さんの#差出人名前#です \n',
    note: '2020年 新卒採用',
  },
  {
    id: 2,
    code: '630eff1b380505a67570dff952ce4ad720210104151058',
    name: '研修連絡4ヶ月',
    subject: 'メッセージをご確認ください',
    context:
      '#名前#さん \nこんにちは。これはテスト１です。\n#差出人組織#さんの#差出人名前#です \n',
    note: '2021年 新卒採用',
  },
  {
    id: 3,
    code: '630eff1b380505a67570dff952ce4ad720210104151059',
    name: '研修連絡5ヶ月',
    subject: 'メッセージをご確認ください',
    context:
      '#名前#さん \nこんにちは。これはテスト２です。\n#差出人組織#さんの#差出人名前#です \n',
    note: '2022年 新卒採用',
  },
]

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      data: message_template[0],
      input: message_template[0],
      success: true,
    },
  }),
  get: ()=> ({
    status: 200,
    resBody: message_template,
  })
})