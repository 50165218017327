import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  put: {
    resBody: string
  }
}>

export default mockMethods<Methods>({
  put: () => ({
    status: 200,
    resBody: 'ok',
  }),
})
