import type { AspidaClient } from 'aspida';
import type { Methods as Methods_o7w2p1 } from './v1/common/import-departments';
import type { Methods as Methods_8hwwky } from './v1/common/users/display-limits';
import type { Methods as Methods_1x239qu } from './v1/common/users/import';
import type { Methods as Methods_vnugg5 } from './v1/obf/update-members';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '');
  const PATH0 = '/v1/common/import-departments';
  const PATH1 = '/v1/common/users/display-limits';
  const PATH2 = '/v1/common/users/import';
  const PATH3 = '/v1/obf/update-members';
  const GET = 'GET';
  const POST = 'POST';

  return {
    v1: {
      common: {
        import_departments: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_o7w2p1['get']['resBody']>(prefix, PATH0, GET, option).blob(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_o7w2p1['get']['resBody']>(prefix, PATH0, GET, option).blob().then(r => r.body),
          post: (option: { body: Methods_o7w2p1['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_o7w2p1['post']['resBody']>(prefix, PATH0, POST, option, 'FormData').json(),
          $post: (option: { body: Methods_o7w2p1['post']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_o7w2p1['post']['resBody']>(prefix, PATH0, POST, option, 'FormData').json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`,
        },
        users: {
          display_limits: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_8hwwky['get']['resBody']>(prefix, PATH1, GET, option).blob(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_8hwwky['get']['resBody']>(prefix, PATH1, GET, option).blob().then(r => r.body),
            $path: () => `${prefix}${PATH1}`,
          },
          import: {
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1x239qu['get']['resBody']>(prefix, PATH2, GET, option).blob(),
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1x239qu['get']['resBody']>(prefix, PATH2, GET, option).blob().then(r => r.body),
            post: (option: { body: Methods_1x239qu['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1x239qu['post']['resBody']>(prefix, PATH2, POST, option, 'FormData').json(),
            $post: (option: { body: Methods_1x239qu['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1x239qu['post']['resBody']>(prefix, PATH2, POST, option, 'FormData').json().then(r => r.body),
            $path: () => `${prefix}${PATH2}`,
          },
        },
      },
      obf: {
        update_members: {
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_vnugg5['get']['resBody']>(prefix, PATH3, GET, option).blob(),
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_vnugg5['get']['resBody']>(prefix, PATH3, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH3}`,
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
