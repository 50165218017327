import type { DefineMethods } from 'aspida'
import type { EnqMailResponse } from '~/types/enqMailResponse'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: EnqMailResponse
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      mail_template_id: 1,
      subject:
        '【#名前#さん】#配信月#月分HR OnBoardアンケートのお願い【回答期限：#回答期限#】',
      context: `#名前# さん
こんにちは。
#差出人 社名#です。

#配信月#月分のHR OnBoardアンケート へのご回答をお願いいたします。
下記のURLより、【#回答期限#まで】に回答をお願いします。

▼ アンケートの回答はこちらから
#アンケートURL#

※回答は１分程度で完了します。

HR OnBoardアンケート とは？
⇒新しく入社された皆様の活躍・定着を目的として、毎月配信されるアンケートサービスです。回答結果は担当者以外に公開されることはありません。

いただいた回答結果を確認し、ささきの ゆうさんの活躍に向けたサポートを
一緒に考えていきたいと思っています。

もし不明点があれば下記までお問い合わせください。
よろしくお願いします。

※本メールは自動で送付されています。返信は本文下部のメールアドレスまでお願いします。※
━━━━━━━━━━━━━━━━━━━━━━━━━━━
#差出人 社名#
#差出人 組織# #差出人 名前#
[E-mail] #返信先メールアドレス#
━━━━━━━━━━━━━━━━━━━━━━━━━━━`,
      default_subject:
        '【#名前#さん】#配信月#月分HR OnBoardアンケートのお願い【回答期限：#回答期限#】',
      default_context: `#名前# さん
こんにちは。
#差出人 社名#です。

#配信月#月分のHR OnBoardアンケート へのご回答をお願いいたします。
下記のURLより、【#回答期限#まで】に回答をお願いします。

▼ アンケートの回答はこちらから
#アンケートURL#

※回答は１分程度で完了します。

HR OnBoardアンケート とは？
⇒新しく入社された皆様の活躍・定着を目的として、毎月配信されるアンケートサービスです。回答結果は担当者以外に公開されることはありません。

いただいた回答結果を確認し、ささきの ゆうさんの活躍に向けたサポートを
一緒に考えていきたいと思っています。

もし不明点があれば下記までお問い合わせください。
よろしくお願いします。

※本メールは自動で送付されています。返信は本文下部のメールアドレスまでお願いします。※
━━━━━━━━━━━━━━━━━━━━━━━━━━━
#差出人 社名#
#差出人 組織# #差出人 名前#
[E-mail] #返信先メールアドレス#
━━━━━━━━━━━━━━━━━━━━━━━━━━━`,
    },
  }),
})
