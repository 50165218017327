import type { DefineMethods } from 'aspida'
import type { SlackTeamInfo } from '~/types/slackTeamInfo'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: SlackTeamInfo
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      name: '',
      image_34: '',
      image_44: '',
      image_68: '',
      image_88: '',
      image_102: '',
      image_132: '',
      image_230: '',
    },
  }),
})
