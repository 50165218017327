import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      name: string
      department: string
      email: string
    }
    resBody: {
      data: object
    }
  }
}>

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: {
      data: {
        status: 'success',
      },
    },
  }),
})
