import { ref, inject } from 'vue'

export type AppLoadingAttrs = {
  isVisible: boolean
}

export type LoadingOptions = {
  isNavigating?: boolean
}

export type UseLoading = {
  appLoadingAttrs: AppLoadingAttrs
  showLoading: (
    isVisible: boolean,
    options?: { isNavigating?: boolean },
  ) => void
  start: () => void
  finish: () => void
  isNavigating: Ref<boolean>
}

export const appLoadingKey = Symbol()

export default function useLoading(): UseLoading {
  const provided = inject<UseLoading>(appLoadingKey)

  if (provided === undefined) {
    throw Error('AppLoading Not Provided')
  }

  return provided
}

export const initializeLoading = (): UseLoading => {
  const appLoadingAttrs = ref<AppLoadingAttrs>({
    isVisible: false,
  })
  const isNavigating = ref<boolean>(false)

  function showLoading(isVisible: boolean, options?: LoadingOptions): void {
    appLoadingAttrs.value.isVisible = isVisible

    if (options?.isNavigating !== undefined) {
      isNavigating.value = options.isNavigating
    }
  }

  function start(): void {
    appLoadingAttrs.value.isVisible = true
  }

  function finish(): void {
    appLoadingAttrs.value.isVisible = false
  }

  return {
    appLoadingAttrs: appLoadingAttrs.value,
    showLoading,
    start,
    finish,
    isNavigating,
  }
}
