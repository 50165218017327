import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqFormat: FormData
    reqBody: {
      file: Blob
    }
    resBody: {
      talent_analytics: Res
    }
  }
}>

type Res = {
  success: true
  errors: ''
  input: ''
}

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      talent_analytics: {
        success: true,
        errors: '',
        input: '',
      },
    },
  }),
})
