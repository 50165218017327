export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"利用企業の平均離職率は13.6％から5.3％に改善！HR OnBoard（HR オンボード）は、入社者への毎月のアンケートによって「離職リスク」が可視化できる無料のWebツールです。"},{"hid":"og:title","property":"og:title","content":"HR Onboard"},{"hid":"og:site_name","property":"og:site_name","content":"入社者の離職リスク可視化ツール HR OnBoard"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://on-board.io"},{"hid":"og:image","property":"og:image","content":"https://api.on-board.io/img/ogimage.png"},{"hid":"og:description","property":"og:description","content":"利用企業の平均離職率は13.6％から5.3％に改善！HR OnBoard（HR オンボード）は、入社者への毎月のアンケートによって「離職リスク」が可視化できる無料のWebツールです。"},{"hid":"facebook-domain-verification","name":"facebook-domain-verification","content":"wo3fjy5v8d27kjdda6tbh2x7oso6we"}],"link":[{"rel":"shortcut icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","sizes":"192x192","type":"image/png","href":"/android-touch-icon.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"}],"style":[],"script":[{"src":"https://code.createjs.com/1.0.0/createjs.min.js"}],"noscript":[],"title":"HR OnBoard","htmlAttrs":{"lang":"ja"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false