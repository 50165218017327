import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqFormat: FormData
    reqBody: {
      file: Blob
    }
    resBody: {
      success: boolean
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    reqFormat: FormData,
    reqBody: {
      file: new Blob(),
    },
    resBody: {
      success: true,
    },
  }),
})
