import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      routes: {
        task: string
        uri: string
      }
      ip: string
    }

    resBody: {
      description: string
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      description: 'ok',
    },
  }),
})
