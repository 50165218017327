import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      total_count: number,
	    use_count: number,
      rest_count: number,
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      total_count: 100,
      use_count: 15,
      rest_count: 85,
    },
  }),
})

