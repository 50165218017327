import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      number: number
    }
    resBody: []
  }
}>

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: [],
  }),
})
