import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { ConditionResponse } from '~/types/chart'
import { conditionCalendarChartBaseData } from './index'

export type Methods = DefineMethods<{
  get: {
    query: {
      from_date?: string
      to_date?: string
      'working_status[]'?: number[] // 1: 在職中 2: 休職中 3: 退職
      'is_newbie[]'?: number[] // 1: 新卒 0: 中途
    }
    resBody: ConditionResponse
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: conditionCalendarChartBaseData,
  }),
})
