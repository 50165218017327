import type { DefineMethods } from 'aspida'
import type { Member } from '~/types/member'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      interview_status?: number,
      importance?: number
    },
    resBody: {
      message: string
    }
  },
  delete: {}
}>

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: {
    	message: '更新しました',
    },
  }),
  delete: () => ({
    status: 200
  })
})
