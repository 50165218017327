import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Member } from '~/types/member'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      texts: string[]
      member?: Member
    }
    resBody: {
      texts: string[]
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      texts: [
        '【既定】コンディション：晴れ',
        'メッセージをご確認ください',
        '（メンバー名が入ります）-（メンバー名が入ります）-（メンバー名が入ります）オンボ太郎',
        '※既定メッセージのため、編集・削除はできません。',
      ],
    },
  }),
})
