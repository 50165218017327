import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Res
  }
}>

type Res = {
  title: string
  body: string
  image_uri: string
  external_link: string
  updated_at: string
}
const res = {
  title:
    '『HR OnBoard』が、経済産業省が推進する「IT導入補助金」の対象サービスに認定されました。',
  body:
    'この取り組みにより、補助金対象事業者は『HR OnBoard』を導入する際に、最大で導入金額の75％の補助を受けることができます。\n' +
    ' <br>『HR OnBoard』のメンバー枠や管理者アカウント枠の追加購入も対象です。（一定の条件・審査がございます。）</br>\n' +
    ' <br>詳細については、こちらをクリックしてご覧ください！</br>',
  image_uri:
    'thumbnail_80ghVe9HXlW7HhEwp1bRKeGeXxHsg6B3PtAkB5ma0CqlXga1ZyRFIChq4gQSWEV6.png',
  external_link: 'https://on-board.io/company/notification/detail/70',
  updated_at: '2020-10-02 15:08:10',
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: res,
  }),
})
