import type { DefineMethods } from 'aspida'
import type { Code } from '~/types/member/code'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: Code
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        id: 1,
        company_id: 1,
        code: 5772441978,
        lastname: 'エン',
        firstname: '太郎',
        lastname_kana: 'スズキ',
        firstname_kana: 'イチロウ',
        employee_code: '100001',
        employing_type: 1,
        working_status: 1,
        date_resigned: '2020-09-17',
        resign_reason: ['7'],
        resign_reason_text: '転職のため',
        gender: 1,
        birth_year: 2010,
        birth_month: 10,
        birth_day: 2,
        mobile_tel_number: '04077779999',
        email: 'test@en-japan.com',
        delivery_date: '2019-08-01',
        profession: 'ディレクター',
        senior_id: 1,
        initial_evaluation: 1,
        is_newbie: 1,
        entry_reason: 'がんばってた',
        recruiter: '高橋花子',
        initial_evaluated_point: '1',
        initial_worry_point: '1',
        route_employed: 1,
        route_employed_detail: '',
        date_contracted: '2017-11-01',
        has_image: 1,
        image_uri: '/img/mock/user5.png',
        condition_type: 1,
        progress_status: 1,
        interview_status: 1,
        importance: 1,
        interview_due_date: '2020/12/31',
        job_change_count: 5,
        working_years: 6,
        graduation_type: 1,
        ex_company: 'テスト',
        ex_profession: 'ディレクター',
        other_info: 'テスト',
        other_info2: 'テスト',
        other_info3: 'テスト',
        is_stopped_send_anniv_mail: 0,
        is_stopped_send_questions_mail: 0,
        mail_language: 0,
        anniv_answer_completed: 1,
        anniv_senior_answer_completed: 1,
        anniv_renewal_sent_date: '2020-01-31',
        anniv_sent: 1,
        age: 22,
        hr_user_id: 23,
        date_quit: '',
        is_deleted: 0,
        created_at: '2020-09-17',
        updated_at: '2020-09-17',
        using_slack: 1,
        slack_email: 'test@en-japan.com',
        slack_user_id: 1,
        bounce_count: 2,
        sms_status: 2,
        line_user_id: '',
        line_status: 2,
        talent_member_id: 1,
        talent_status: 1,
        line_works_id: 1,
        line_works_send_success_flag: 0,
        bounce_reason: '',
        app_member_token: '',
        push_enabled: 1,
        app_status: 2,
        has_answer: true,
        department: {
          id: 1,
          name: '東京営業所',
          full_path: '営業部 > 営業第二課 > 東京営業所',
        },
      },
    },
  }),
})
