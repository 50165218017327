import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Company } from '~/types/company'

export type Methods = DefineMethods<{
  patch: {
    reqBody: {
      is_anniv_complete_notice: number
      anniv_complete_notice_email: string
    }
    resBody: Company
  }
}>

const company = {
  id: 1,
  name: 'デモ用(新規作成)',
  max_account_count: 1,
  max_member_count: 50,
  is_active: 1,
  tutorial_done: 0,
  sender_name: '清水 健二',
  sender_email: 'demo@en-japan.com',
  sender_department: '人事部',
  bounce_count: 0,
  plan_id: 1,
  options: null,
  timeline_download_flag: 0,
  created_at: '2020-01-01 00:00:00',
  updated_at: '2020-01-01 00:00:00',
  engage_id: 1,
  dashboard_list_count: null,
  member_list_count: 50,
  slack_access_token: null,
  slack_team_name: null,
  line_works_domain_id: null,
  line_works_api_id: null,
  line_works_consumer_key: null,
  line_works_token1: null,
  line_works_token2: null,
  line_works_admin_id: null,
  line_works_linked_bot_no: null,
  lastlogin_at: '2020-11-01 00:00:00',
  first_send_mail_flag: 1,
  receive_anniv_email: 1 as 0 | 1,
  receive_anniv_email_to_senior: 1 as 0 | 1,
  bounce_reason: null,
  is_enq_complete_notice: 0 as 0 | 1,
  is_anniv_complete_notice: 1 as 0 | 1,
  enq_complete_notice_email: 'demo@en-japan.com',
  anniv_complete_notice_email: 'demo@en-japan.com',
  is_enq_complete_notice_sunny: 0 as 0 | 1,
  is_enq_complete_notice_cloudy: 0 as 0 | 1,
  is_enq_complete_notice_rainy: 0 as 0 | 1,
  enq_complete_notice_email_bounce_count: 0,
  anniv_complete_notice_email_bounce_count: 0,
  report_format_text: 1,
  report_format_html: 1,
  is_thanks_notice: 0 as 0 | 1,
  is_thanks_notice_sunny: 0 as 0 | 1,
  is_thanks_notice_cloudy: 0 as 0 | 1,
  is_thanks_notice_rainy: 0 as 0 | 1,
  max_reading_account_count: 1,
  is_send_start_mail: true,
  is_send_monthly_mail: true,
  is_send_anniversary_mail: true,
  is_send_summary_mail: true,
  first_register_email: '',
}

export default mockMethods<Methods>({
  patch: () => ({
    status: 200,
    resBody: company,
  }),
})
