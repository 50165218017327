<template>
  <button
    :class="{
      '-default': variant === 'default',
      '-primary': variant === 'primary',
      '-secondary': variant === 'secondary',
      '-danger': variant === 'danger',
      '-white': variant === 'white',
      '-small': size === 'small',
      '-medium': size === 'medium',
      '-large': size === 'large',
    }"
    v-bind="$attrs"
    class="app-button"
    @click="click"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
export type AppButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'white'

interface Props {
  variant?: AppButtonVariant
  size?: 'small' | 'medium' | 'large'
}

interface Emits {
  (e: 'click'): void
}

withDefaults(defineProps<Props>(), {
  variant: 'default',
  size: 'small',
})

const emit = defineEmits<Emits>()

const click: () => void = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.app-button {
  padding: 10px 30px;
  box-sizing: border-box;
  font-weight: $font-weight-bold;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 27px;
  outline: none;

  &.-default {
    color: $primary-color;
    background-color: $white-color;
    border: 1px solid $primary-color;

    &:hover {
      color: $white-color;
      background-color: $primary-color;
    }
  }

  &.-primary {
    color: $white-color;
    background: linear-gradient(90deg, $blue-2, $blue-3);
    box-shadow: 0 3px 15px $gray-transparent;

    &:hover {
      background: linear-gradient(90deg, $blue-7, $link-hover);
    }
  }

  &.-secondary {
    color: $white-color;
    background: linear-gradient(90deg, $lighter-orange, $light-orange);
    box-shadow: 0 3px 15px $gray-transparent;

    &:hover {
      color: $white-color;
      background: linear-gradient(90deg, $light-orange, $light-orange2);
    }
  }

  &.-danger {
    color: $danger-color;
    background-color: $white-color;
    border: 1px solid $danger-color;

    &:hover {
      color: $white-color;
      background: $danger-color;
    }
  }

  &.-white {
    color: $dark-blue-color;
    background-color: $white-color;
  }

  &:disabled {
    color: $white-color;
    cursor: default;
    background: $gray-9;
    border-color: $gray-9;

    &:hover {
      color: $white-color;
      background: $gray-9;
    }
  }

  &.-small {
    font-size: $font-size-small;
    line-height: $lh-16;
  }

  &.-medium {
    font-size: $font-size-16;
    line-height: $lh-16;
  }

  &.-large {
    font-size: $font-size-large;
    line-height: $lh-16;
  }
}
</style>
