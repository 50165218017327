import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { TalentLinkMember } from '~/types/talentMember'

export type Methods = DefineMethods<{
  get: {
    query?: {
      limit: number
      offset: number
    }
    resBody: {
      members: TalentLinkMember[]
      count: number
    }
  }
}>

const talent_link_members = {
  count: 1,
  members: [
    {
      id: 176,
      lastname: 'テスト',
      firstname: '太郎',
      email: 'onboard01@en-japan.com',
      talent_last_name: 'テスト',
      talent_first_name: '太郎',
      talent_email: 'talent_link_member01@en-japan.com',
    },
    {
      id: 177,
      lastname: 'テスト',
      firstname: '次郎',
      email: 'onboard02@en-japan.com',
      talent_last_name: 'テスト',
      talent_first_name: '次郎',
      talent_email: 'talent_link_member02@en-japan.com',
    },
    {
      id: 178,
      lastname: 'テスト',
      firstname: '三郎',
      email: 'onboard03@en-japan.com',
      talent_last_name: 'テスト',
      talent_first_name: '三郎',
      talent_email: 'talent_link_member03@en-japan.com',
    },
  ],
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: talent_link_members,
  }),
})
