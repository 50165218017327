import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    responseType: 'blob'
    resBody: Blob
    query?: {
      search_from: string
      search_to: string
    }
  }
}>
export default mockMethods<Methods>({
  get: () => ({
    responseType: 'blob',
    status: 200,
    resBody: new Blob(['']),
  }),
})
