import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { CompanySecuritySetting } from '~/types/companySecuritySetting'

export type Methods = DefineMethods<{
  get: {
    resBody: CompanySecuritySetting
  }
  patch: {
    reqBody: {
      password_complexity?: number
      password_upper_and_lower?: number
      password_length?: number
      password_valid_days?: number
      allow_past_password_count?: number
      allowed_ip_addresses?: string
    }
    resBody: {
      emails: string[]
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      id: 1,
      company_id: 1,
      is_active: 1,
      password_complexity: 0,
      password_upper_and_lower: 0,
      password_length: 8,
      password_valid_days: 0,
      allow_past_password_count: 1,
      allowed_ip_addresses: '172.18.0.1',
      created_at: '2020-07-31 11:24:57',
      updated_at: '2020-07-31 11:24:57',
    },
  }),
  patch: () => ({
    status: 200,
    resBody: {
      emails: ['test@example.com'],
    },
  }),
})
