import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Actionlog } from '~/types/actionlog'

export type Methods = DefineMethods<{
  get: {
    resBody: Actionlog[],
    query: { id : number},
  },
  post: {
    resBody: {},
    reqBody: {
        comment: string | null,
        hr_user_id: number | null | undefined,
        interview_status: number | null,
        importance: number | null,
        impression: number | null,
        interview_due_date: string | null
    },
    query: { id : number}
  }
}>
const actionlogs = [
  {
    id: 1,
    company_id: 1,
    member_id: 1,
    action_type: 1,
    answer_id: 0,
    poster: '清水 健二',
    hr_user_id: 1,
    hr_user_name: '清水 健二',
    changer_user_id: 1,
    interview_status: 0,
    interview_due_date: '2020-01-02',
    importance: 1,
    comment:
      '返信を確認する限り、想定よりも心配はなさそうだった。成長意欲が高い人であることから、業務量が多いことはマイナス要素ではなさそう。配属から3ヶ月経過し、成果が出始めればコンディションがよくなる可能性が高い。念の為上司へのヒアリングも行う予定。',
    impression: 1,
    created_at: '2021-02-09 09:40:00',
    updated_at: '2021-02-09 09:40:00',
  },
]
export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: actionlogs
  }),
  post: () => ({
    status: 200,
    resBody: {}
  }),
})
