import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Senior } from '~/types/senior'

export type Methods = DefineMethods<{
  put: {
    reqBody: Senior
    resBody: {
      message: string
    }
  }
}>

export default mockMethods<Methods>({
  put: () => ({
    status: 200,
    resBody: {
      message: 'success',
    },
  }),
})
