import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { CompanyService } from '~/types/companyService'

export type Methods = DefineMethods<{
  get: {
    resBody: CompanyService[]
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: [
      {
        id: 1,
        site_id: 1,
        company_id: 1234,
        joined_company_id: 4534,
        joined_company_name: '株式会社テスト',
        link_status: 1,
        linked_at: '2020/9/14 15:59:51',
        created_at: '2020/9/14 15:59:51',
        updated_at: '2020/9/14 15:59:51',
      },
    ],
  }),
})
