<template>
  <div class="app">
    <div
      v-if="isMaintenance"
      class="maintenance"
    >
      <div class="gradient"></div>
      <h2 class="title">{{ error.statusMessage }}</h2>
      <div
        class="message"
        v-html="error.message"
      ></div>
    </div>
    <template v-else>
      <div class="main">
        <div class="error_box">
          <div class="box">
            <div class="message">
              <div v-if="isNotFound">Not Found</div>
              <div v-if="isInternalError">Internal Error</div>
            </div>
            <h2 class="status">{{ error.statusCode }}</h2>
            <app-button
              class="button"
              variant="primary"
              size="medium"
              @click="handleError"
            >
              ホームへ
            </app-button>
          </div>
        </div>
      </div>
      <app-footer class="footer" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import { HttpStatusCode } from 'axios'

const props = defineProps<{ error: NuxtError }>()
const handleError = (): Promise<void> => clearError({ redirect: '/' })

const isMaintenance = computed(
  () => props.error.statusCode === HttpStatusCode.ServiceUnavailable,
)

const isNotFound = computed(
  () => props.error.statusCode === HttpStatusCode.NotFound,
)

const isInternalError = computed(
  () => props.error.statusCode === HttpStatusCode.InternalServerError,
)
</script>

<style lang="scss" scoped>
.app {
  .main {
    position: relative;
    z-index: 101;
    width: 100%;
    padding-bottom: 5px;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 322px;
      left: -105px;
      z-index: 100;
      width: 119%;
      height: 635px;
      transform: rotate(14deg);
    }

    &::after {
      position: absolute;
      top: 525px;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  .error_box {
    position: relative;
    z-index: 101;
    width: 100%;
    padding-bottom: 25px;

    > .box {
      width: 330px;
      padding: 0 0 25px;
      margin: 260px auto auto;
      color: $gray-19;
      text-align: center;

      > .message {
        margin: 0;
        font-size: $font-size-40;
      }

      > .status {
        margin: 0 0 46px;
        font-size: $font-size-130;
      }

      > .home {
        > .button {
          width: 170px;
        }
      }
    }
  }

  .maintenance {
    .gradient {
      width: 100%;
      min-height: 540px;
      position: fixed;
      top: 0;
      bottom: 0;
      overflow: hidden;
      background: $blue-9;
      z-index: -1;
      padding-bottom: 5px;

      &::before {
        content: '';
        width: 119%;
        height: 635px;
        position: absolute;
        top: 322px;
        left: -105px;
        background-color: $bg-white-2;
        transform: rotate(14deg);
        z-index: -1;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 525px;
        left: 0;
        background-color: $bg-white-2;
        z-index: -1;
      }
    }

    > .title {
      font-size: $font-size-32;
      color: $dark-blue-color;
      text-align: center;
    }

    > .message {
      font-size: $font-size-20;
      color: $dark-blue-color;
      text-align: center;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
