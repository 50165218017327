import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Res
  }
}>

type Res = {
  conditionData: {
    delivered_num: number
    answer_num: number
    not_answer_num: number
    sunny_num: number
    cloudy_num: number
    rainy_num: number
  }
  monthLastEnq: string
  firstDeliveryDate: string
}

const conditionData = {
  delivered_num: 5,
  answer_num: 3,
  not_answer_num: 2,
  sunny_num: 1,
  cloudy_num: 1,
  rainy_num: 1,
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      conditionData,
      monthLastEnq: '2021年4月',
      firstDeliveryDate: '2017-12-01',
    },
  }),
})
