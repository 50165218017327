import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { CompanySecurityLog } from '~/types/companySecurityLog'

export type Methods = DefineMethods<{
  get: {
    query: {
      category: string
      start_date: string
      end_date: string
      limit: number
      offset: number
    }
    resBody: {
      companySecurityLog: CompanySecurityLog[]
      count: number
    }
  }
}>

const company_security_log = [
  {
    id: 1,
    company_id: 1,
    uri: '/company/dashboard/index',
    category: 'ホーム',
    task: 'ダッシュボード表示',
    result: 1,
    result_note: null,
    user_id: 3333,
    user_name: 'テスト太郎',
    ip_address: '172.18.0.1',
    created_at: '2020-07-15 18:42:26',
    updated_at: '2020-07-15 18:42:26',
  },
  {
    id: 2,
    company_id: 1,
    uri: '/company/dashboard/index',
    category: 'ホーム',
    task: 'ダッシュボード表示',
    result: 1,
    result_note: null,
    user_id: 3333,
    user_name: 'テスト太郎',
    ip_address: '172.18.0.1',
    created_at: '2020-07-12 18:42:26',
    updated_at: '2020-07-12 18:42:26',
  },
  {
    id: 3,
    company_id: 1,
    uri: '/company/dashboard/index',
    category: '回答レポート',
    task: '回答レポート',
    result: 1,
    result_note: null,
    user_id: 3333,
    user_name: 'テスト太郎',
    ip_address: '172.18.0.1',
    created_at: '2020-07-11 18:42:26',
    updated_at: '2020-07-11 18:42:26',
  },
  {
    id: 4,
    company_id: 1,
    uri: '/company/member/index',
    category: 'メンバー一覧',
    task: 'メンバー一覧',
    result: 0,
    result_note: null,
    user_id: 3333,
    user_name: 'テスト太郎',
    ip_address: '172.18.2.2',
    created_at: '2020-04-15 18:42:26',
    updated_at: '2020-04-15 18:42:26',
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      companySecurityLog: company_security_log,
      count: 4,
    },
  }),
})
