import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Senior } from '~/types/senior'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      department_id?: number | string | undefined | null
      email: string
      firstname: string
      lastname: string
      receiving_bcc: boolean | number
      receiving_report: boolean | number
    }

    resBody: {
      message: string
    }
  }
  get: {
    resBody: {
      result: Senior[]
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      message: '上司を登録しました',
    },
  }),
  get: () => ({
    status: 200,
    resBody: {
      result: [
        {
          id: 1,
          email: '7886d146b8@en-japan.com',
          firstname: '美奈',
          lastname: '河野',
          department: '',
          department_id: 1,
          department_name: 'デモ株式会社 > 営業部',
          receiving_report: 1,
          receiving_bcc: 1,
        },
        {
          id: 2,
          department_id: 2,
          department_name: 'デモ株式会社 > 管理部',
          email: '7984ec735c@en-japan.com',
          receiving_report: 1,
          receiving_bcc: 1,
          firstname: '朝子',
          lastname: '坂本',
          department: '',
        },
      ],
    },
  }),
})
