import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { MessageTemplate } from '~/types/messageTemplate'

export type Methods = DefineMethods<{
  get: {
    resBody: MessageTemplate
  }
}>

const masterMessageTemplate = {
  name: '【既定】コンディション：晴れ',
  subject: 'メッセージをご確認ください',
  context:
    'こんにちは、#差出人 名前#です。\n\n\
  今月のアンケートにご回答いただきまして、\n\
  ありがとうございました。\n\n\
  回答いただいた内容から、日頃の業務に前向き\n\
  に取り組んでいらっしゃる様子が伺えました。\n\n\
  今後も毎月1日にアンケートをお送りしますので相談\n\
  したいこと等がありましたら、何でも記載してください。\n\n\
  #名前 姓#さんのこれからの活躍に向けてサポートを\n\
  していきたいと思っています。\n\n\
  それでは、引き続きよろしくお願いします。\n\n\
  ※ 返信の際は、送信元のアドレスを変えずにご返信ください\n\
   ※━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\
   #差出人 社名#\n\
   #差出人 組織# / #差出人 名前#\n\
   ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━',
  note: '※既定メッセージのため、編集・削除はできません。',
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: masterMessageTemplate,
  }),
})
