import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { UserSettingMail } from '~/types/user'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: {
        users: UserSettingMail[]
        viewer: UserSettingMail
      }
    }
  }
  put: {
    reqBody: {
      users: UserSettingMail[]
      viewer: UserSettingMail
    }
    resBody: {
      message: string
    }
  }
}>

const users = [
  {
    id: 1,
    name: '河野 美奈',
    email: 'mina_k@en-japan.com',
    department_id: 3,
    department_name: '管理部',
    roles: [
      {
        key: 'super_administrator' as const,
        name: 'SUPER_ADMINISTRATOR',
        short_name: '全体管理',
      },
    ],
    is_send_start_mail: true,
    is_send_monthly_mail: false,
    is_send_anniversary_mail: false,
    is_send_summary_mail: false,
  },
  {
    id: 2,
    name: '坂本 朝子',
    department_id: 2,
    department_name: '営業部',
    email: 'asako_s@en-japan.com',
    roles: [
      {
        key: 'super_administrator' as const,
        name: 'SUPER_ADMINISTRATOR',
        short_name: '全体管理',
      },
    ],
    is_send_start_mail: true,
    is_send_monthly_mail: true,
    is_send_anniversary_mail: false,
    is_send_summary_mail: false,
  },
  {
    id: 3,
    name: '三島 カナ',
    department_id: 3,
    department_name: '管理部',
    email: 'kana_m@en-japan.com',
    roles: [
      {
        key: 'standard_viewer' as const,
        name: 'STANDARD_VIEWER',
        short_name: '標準閲覧',
      },
    ],
    is_send_start_mail: true,
    is_send_monthly_mail: true,
    is_send_anniversary_mail: true,
    is_send_summary_mail: false,
  },
  {
    id: 4,
    name: '四門 洋子',
    department_id: 2,
    department_name: '営業部',
    email: 'yoko_s@en-japan.com',
    roles: [
      {
        key: 'restricted_viewer' as const,
        name: 'RESTRICTED_VIEWER',
        short_name: '制限閲覧',
      },
    ],
    is_send_start_mail: true,
    is_send_monthly_mail: true,
    is_send_anniversary_mail: true,
    is_send_summary_mail: true,
  },
]

const viewer = {
  name: '標準閲覧アカウント',
  is_send_start_mail: false,
  is_send_monthly_mail: true,
  is_send_anniversary_mail: false,
  is_send_summary_mail: true,
  roles: [
    {
      key: 'standard_viewer' as const,
      name: 'STANDARD_VIEWER',
      short_name: '標準閲覧',
    },
  ],
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        users: users,
        viewer: viewer,
      },
    },
  }),
  put: () => ({
    status: 200,
    resBody: {
      message: 'アカウント自動通知設定メールを保存しました',
    },
  }),
})
