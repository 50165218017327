import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  put: {
    resBody: {},
    reqBody: {
        comment: string | null,
        hr_user_id: number | null | undefined,
        interview_status: number | null,
        importance: number | null,
        impression: number | null,
        interview_due_date: string | null
    },
  },
  delete: {
    resBody: {}
  }
}>

export default mockMethods<Methods>({
  put: () => ({
    status: 200,
    resBody: {},
  }),
  delete: () => ({
    status: 200,
    resBody: {},
  }),
})