<template>
  <Transition name="fade-in-down">
    <div
      v-if="flashMessageAttrs.isVisible"
      class="app-flash-message"
    >
      <div
        class="card"
        :class="{
          '-success': flashMessageAttrs.type === 'success',
          '-fail': flashMessageAttrs.type === 'fail',
        }"
      >
        <div class="item">
          <div class="icon">
            <app-icon
              v-if="flashMessageAttrs.type === 'fail'"
              name="checkCircleRed"
              width="24"
              height="24"
            />
            <app-icon
              v-if="flashMessageAttrs.type === 'success'"
              name="checkCircleGreen"
              width="24"
              height="24"
            />
          </div>
          <div class="title">
            {{ flashMessageAttrs.message }}
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import type { UseFlashMessage } from '~/composables/useFlashMessages'
import { appFlashMessageKey } from '~/composables/useFlashMessages'

const provided = inject<UseFlashMessage>(appFlashMessageKey)

if (provided === undefined) {
  throw Error('FlashMessage Not Provided')
}

const { flashMessageAttrs } = provided
</script>

<style lang="scss" scoped>
.fade-in-down-enter-active {
  transition: all 1s ease-out;
}

.fade-in-down-leave-active {
  transition: all 1.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-in-down-enter-from,
.fade-in-down-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}

.app-flash-message {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-flashMessage;
  width: 100%;

  > .card {
    display: flex;
    max-width: 612px;
    height: 76px;
    margin: 7px auto 0;
    border-radius: 10px;
    align-items: center;

    &.-success {
      color: $green;
      background: $light-green;
    }

    &.-fail {
      color: $red;
      background: $light-red;
    }

    > .item {
      display: flex;
      align-items: center;
      margin: 0 1em 0 1.5em;

      > .title {
        margin-left: 13px;
        font-size: $font-size-18;
        line-height: $lh-24;
      }
    }
  }
}
</style>
