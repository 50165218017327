<template>
  <footer class="app-footer">
    <div class="copyright">Copyright© en Japan Inc. All Rights reserved.</div>
    <div class="menu">
      <nuxt-link
        to="/company/help/faq"
        class="item"
        >ヘルプ</nuxt-link
      >
      <nuxt-link
        to="/terms"
        target="_blank"
        rel="noopener noreferrer"
        class="item"
        >利用規約</nuxt-link
      >
      <a
        href="https://corp.en-japan.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="item"
        >運営会社</a
      >
      <a
        :href="$t('uri.help')"
        target="_blank"
        rel="noopener noreferrer"
        class="item"
        >お問い合わせ</a
      >
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.app-footer {
  display: flex;
  height: $lh-41;
  font-size: $font-size-small;
  color: $white-color;
  background-color: $dark-blue-color;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 20px;

  > .menu {
    > .item {
      display: inline-block;
      padding: 0 17px;
      vertical-align: middle;
      border-right: 1px solid $white-color;

      &:last-child {
        border-right-width: 0;
      }
    }
  }
}
</style>
