import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: Res
  }
}>

type Res = {
  allAverages: {
    success: number
    motivation: number
  }
  companyAverages: {
    success: number
    motivation: number
  }
  deliveredCount: number
  renewalDeliveredCount: number
  notDeliveredCount: number
  answeredCount: number
}

const res = {
  allAverages: {
    success: 3.2,
    motivation: 3.3,
  },
  companyAverages: {
    success: 3.5,
    motivation: 3.5,
  },
  deliveredCount: 21,
  renewalDeliveredCount: 3,
  notDeliveredCount: 8,
  answeredCount: 18,
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: res,
  }),
})
