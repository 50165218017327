import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      email: string
      password: string
    }

    resBody: {
      data: object
    }
  }
}>

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      data: {
        status: 'success',
      },
    },
  }),
})
