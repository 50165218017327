import type { DefineMethods } from 'aspida'
import type { User, UserForm } from '~/types/user'
import type { Role } from '~/types/role'
import type { RoleKey } from '~/types/roleKey'
import type { UsersDepartmentListItem } from '~/types/department'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    query?: {
      'role_keys[]': RoleKey[]
    }
    resBody: {
      result: {
        users: User[]
        roles: Role[]
        departments?: UsersDepartmentListItem[]
      }
    }
  }
  post: {
    reqBody: UserForm
    resBody: {
      message: string
    }
  }
}>

const mockBaseData = {
  currentIp: '',
  current_ip_is_valid: false,
  department: null,
  has_security_log: false,
  new_password_needed: false,
}

const admin = [
  {
    ...mockBaseData,
    ...{
      id: 1,
      lastname: '河野',
      firstname: '美奈',
      name: '河野 美奈',
      image_uri: '/img/mock/user1.png',
      is_login_user: false,
      department_id: 3,
      email: 'mina_k@en-japan.com',
      roles: [
        {
          key: 'owner' as const,
          name: '全体管理アカウント',
          short_name: 'オーナー',
          value: '',
          label: '',
          permissions: [],
          type: '',
        },
      ],
      permissions: [],
      display_limit: 0,
      owner: 1,
    },
  },
  {
    ...mockBaseData,
    ...{
      id: 2,
      lastname: '坂本',
      firstname: '朝子',
      name: '坂本 朝子',
      image_uri: '/img/mock/user2.png',
      is_login_user: false,
      department_id: 2,
      email: 'asako_s@en-japan.com',
      roles: [
        {
          key: 'super_administrator' as const,
          name: '全体管理アカウント',
          short_name: '全体管理',
          value: '',
          label: '',
          permissions: [],
          type: '',
        },
      ],
      permissions: [],
      display_limit: 0,
      owner: 0,
    },
  },
]

const viewer = [
  {
    ...mockBaseData,
    ...{
      id: 3,
      lastname: '三島',
      firstname: 'カナ',
      name: '三島 カナ',
      image_uri: '/img/mock/user3.png',
      is_login_user: true,
      department_id: 3,
      email: 'kana_m@en-japan.com',
      roles: [
        {
          key: 'standard_viewer' as const,
          name: '標準閲覧アカウント',
          short_name: '標準閲覧',
        },
      ],
      permissions: [],
      display_limit: 0,
      owner: 0,
    },
  },
  {
    ...mockBaseData,
    ...{
      id: 4,
      lastname: '四門',
      firstname: '洋子',
      name: '四門 洋子',
      image_uri: '/img/mock/user4.png',
      is_login_user: false,
      department_id: 2,
      email: 'yoko_s@en-japan.com',
      roles: [
        {
          key: 'restricted_viewer' as const,
          name: '制限閲覧アカウント',
          short_name: '制限閲覧',
        },
      ],
      display_limit: 1,
      owner: 0,
    },
  },
]

const departments = [
  { id: 1, department_name: 'デモ株式会社' },
  { id: 2, department_name: 'デモ株式会社 > 営業部' },
  { id: 3, department_name: 'デモ株式会社 > 管理部' },
]

const roles = [
  {
    key: 'super_administrator' as const,
    name: '全体管理アカウント',
    short_name: '全体管理',
    type: 'administrator',
    permissions: [],
    value: '',
    label: '',
  },
  {
    key: 'limited_administrator' as const,
    name: '限定管理アカウント',
    short_name: '限定管理',
    type: 'administrator',
    permissions: [],
    value: '',
    label: '',
  },
  {
    key: 'standard_viewer' as const,
    name: '標準閲覧アカウント',
    short_name: '標準閲覧',
    type: 'viewer',
    permissions: [],
    value: '',
    label: '',
  },
  {
    key: 'restricted_viewer' as const,
    name: '制限閲覧アカウント',
    short_name: '制限閲覧',
    type: 'viewer',
    permissions: [],
    value: '',
    label: '',
  },
]

export default mockMethods<Methods>({
  get: ({ query }) => ({
    status: 200,
    resBody: {
      result: {
        users: query?.['role_keys[]'].includes('owner') ? admin : viewer,
        roles,
        departments,
      },
    },
  }),
  post: () => ({
    status: 200,
    resBody: {
      message: 'アカウントに田中さんを登録しました',
    },
  }),
})
