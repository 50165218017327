import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      result: {
        administrator_users_count: number
        max_account_count: number
        viewer_users_count: number
        max_reading_account_count: number
      }
    }
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      result: {
        administrator_users_count: 2,
        max_account_count: 3,
        viewer_users_count: 2,
        max_reading_account_count: 4,
      },
    },
  }),
})
