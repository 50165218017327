import type { AspidaClient } from 'aspida';
import type { MockClient, MockConfig } from 'aspida-mock';
import { mockClient } from 'aspida-mock';
import api from './$api';
import mock0 from './v4/obf/members/search/index';
import mock1 from './v4/obf/members/message/index';
import mock2 from './v4/obf/members/index';
import mock3 from './v4/obf/members/anniversary/index';
import mock4 from './v4/obf/members/_id@number';
import mock5 from './v4/obf/members/_code@string/index';
import mock6 from './v4/obf/emotion-answers/feedback/_id@number/index';
import mock7 from './v4/common/users/mail/index';
import mock8 from './v4/common/users/index';
import mock9 from './v4/common/users/count/index';
import mock10 from './v4/common/users/_id@number/index';
import mock11 from './v4/common/users/_id@number/image';
import mock12 from './v4/common/seniors/index';
import mock13 from './v4/common/seniors/_id@number/index';
import mock14 from './v4/common/departments/search/index';
import mock15 from './v4/common/departments/index';
import mock16 from './v4/common/departments/archive/index';
import mock17 from './v4/common/departments/_id@number/members/index';
import mock18 from './v4/common/departments/_id@number/index';
import mock19 from './v4/common/department-managers/index';
import mock20 from './v4/common/department-managers/_id@number/index';
import mock21 from './v4/common/company/monthly-mail/index';
import mock22 from './v4/auth/me/index';
import mock23 from './v3/template_contents/preview';
import mock24 from './v3/temp_members/index';
import mock25 from './v3/temp_members/_id@number/index';
import mock26 from './v3/talent_analytics/talent_unlink_members';
import mock27 from './v3/talent_analytics/talent_members';
import mock28 from './v3/talent_analytics/talent_link_members';
import mock29 from './v3/talent_analytics/synchronize';
import mock30 from './v3/talent_analytics/relation/index';
import mock31 from './v3/talent_analytics/relation/_id@number/index';
import mock32 from './v3/talent_analytics/pdf';
import mock33 from './v3/talent_analytics/link';
import mock34 from './v3/talent_analytics/csv_talent_import';
import mock35 from './v3/sms/members';
import mock36 from './v3/sms/auth/personal';
import mock37 from './v3/sms/auth/cancel';
import mock38 from './v3/sms/auth/all';
import mock39 from './v3/slack/team';
import mock40 from './v3/slack/members';
import mock41 from './v3/slack/auth/workspace_clear';
import mock42 from './v3/slack/auth/personal';
import mock43 from './v3/slack/auth/cancel';
import mock44 from './v3/settings/security/register';
import mock45 from './v3/settings/security/password_reset_email';
import mock46 from './v3/settings/security/log';
import mock47 from './v3/settings/security/index';
import mock48 from './v3/settings/security/download_logs';
import mock49 from './v3/settings/security/categories';
import mock50 from './v3/seniors/index';
import mock51 from './v3/seniors/_id@number/index';
import mock52 from './v3/refresh-token/index';
import mock53 from './v3/password_reset_execute/index';
import mock54 from './v3/password_reset/index';
import mock55 from './v3/notifications/index';
import mock56 from './v3/notifications/_notificationId@number/index';
import mock57 from './v3/notification_special/index';
import mock58 from './v3/messages/templates/index';
import mock59 from './v3/messages/templates/_code@string/index';
import mock60 from './v3/messages/send/index';
import mock61 from './v3/messages/master_templates/index';
import mock62 from './v3/messages/master_templates/_code@string/index';
import mock63 from './v3/messages/index';
import mock64 from './v3/messages/_id@string/index';
import mock65 from './v3/members/unread';
import mock66 from './v3/members/read/_id@number/index';
import mock67 from './v3/members/monthly/index';
import mock68 from './v3/members/index';
import mock69 from './v3/members/first_delivery/index';
import mock70 from './v3/members/download/index';
import mock71 from './v3/members/bounce_mail_count/index';
import mock72 from './v3/members/anniversaries/count';
import mock73 from './v3/members/anniv_answers_list/index';
import mock74 from './v3/members/_id@number/test_mail';
import mock75 from './v3/members/_id@number/mail_schedule';
import mock76 from './v3/members/_id@number/index';
import mock77 from './v3/members/_id@number/anniv_read';
import mock78 from './v3/members/_id@number/anniv_answers';
import mock79 from './v3/mailtests/welcome';
import mock80 from './v3/mailtests/thanks';
import mock81 from './v3/mailtests/senior-report';
import mock82 from './v3/mailtests/monthly';
import mock83 from './v3/mailtests/monthly-summary';
import mock84 from './v3/mailtests/monthly-remind';
import mock85 from './v3/mailtests/monthly-complete';
import mock86 from './v3/mailtests/monthly-all';
import mock87 from './v3/mailtests/annual';
import mock88 from './v3/mailtests/annual-remind';
import mock89 from './v3/mailtests/annual-old';
import mock90 from './v3/mail_settings/save/index';
import mock91 from './v3/mail_settings/preview/index';
import mock92 from './v3/mail_settings/index';
import mock93 from './v3/logout/index';
import mock94 from './v3/login/index';
import mock95 from './v3/line/members';
import mock96 from './v3/line/auth/personal';
import mock97 from './v3/line/auth/cancel';
import mock98 from './v3/line/auth/all';
import mock99 from './v3/ip/index';
import mock100 from './v3/enqs/index';
import mock101 from './v3/enqs/custom_monthly_questions/index';
import mock102 from './v3/enqs/custom_monthly_questions/_id@number/index';
import mock103 from './v3/enq_mail_settings/_enqMsgType@number/index';
import mock104 from './v3/csv_update_import/index';
import mock105 from './v3/csv_registration_import/index';
import mock106 from './v3/company_services/link';
import mock107 from './v3/company_services/index';
import mock108 from './v3/company_counts/index';
import mock109 from './v3/companies/settings/viewers/_id@number/index';
import mock110 from './v3/companies/settings/viewers';
import mock111 from './v3/companies/settings/senders';
import mock112 from './v3/companies/settings/monthly_thanks';
import mock113 from './v3/companies/settings/member_list_count';
import mock114 from './v3/companies/settings/initial_mails';
import mock115 from './v3/companies/pages/index';
import mock116 from './v3/companies/index';
import mock117 from './v3/companies/annual_notification';
import mock118 from './v3/companies/annual_confirmation';
import mock119 from './v3/avatars/_url@string/index';
import mock120 from './v3/answers/xlsx';
import mock121 from './v3/answers/report/index';
import mock122 from './v3/answers/report/count';
import mock123 from './v3/answers/report/condition-detail';
import mock124 from './v3/answers/report/condition-count';
import mock125 from './v3/answers/report/condition-calendar';
import mock126 from './v3/answers/report/calendar';
import mock127 from './v3/answers/chart';
import mock128 from './v3/anniversaries/xlsx';
import mock129 from './v3/anniversaries/xlsx-new';
import mock130 from './v3/anniversaries/report';
import mock131 from './v3/actionlogs/index';
import mock132 from './v3/actionlogs/_id@number/index';

export const mockRoutes = () => [
  { path: '/v4/obf/members/search', methods: mock0 },
  { path: '/v4/obf/members/message', methods: mock1 },
  { path: '/v4/obf/members', methods: mock2 },
  { path: '/v4/obf/members/anniversary', methods: mock3 },
  { path: '/v4/obf/members/_id@number', methods: mock4 },
  { path: '/v4/obf/members/_code@string', methods: mock5 },
  { path: '/v4/obf/emotion-answers/feedback/_id@number', methods: mock6 },
  { path: '/v4/common/users/mail', methods: mock7 },
  { path: '/v4/common/users', methods: mock8 },
  { path: '/v4/common/users/count', methods: mock9 },
  { path: '/v4/common/users/_id@number', methods: mock10 },
  { path: '/v4/common/users/_id@number/image', methods: mock11 },
  { path: '/v4/common/seniors', methods: mock12 },
  { path: '/v4/common/seniors/_id@number', methods: mock13 },
  { path: '/v4/common/departments/search', methods: mock14 },
  { path: '/v4/common/departments', methods: mock15 },
  { path: '/v4/common/departments/archive', methods: mock16 },
  { path: '/v4/common/departments/_id@number/members', methods: mock17 },
  { path: '/v4/common/departments/_id@number', methods: mock18 },
  { path: '/v4/common/department-managers', methods: mock19 },
  { path: '/v4/common/department-managers/_id@number', methods: mock20 },
  { path: '/v4/common/company/monthly-mail', methods: mock21 },
  { path: '/v4/auth/me', methods: mock22 },
  { path: '/v3/template_contents/preview', methods: mock23 },
  { path: '/v3/temp_members', methods: mock24 },
  { path: '/v3/temp_members/_id@number', methods: mock25 },
  { path: '/v3/talent_analytics/talent_unlink_members', methods: mock26 },
  { path: '/v3/talent_analytics/talent_members', methods: mock27 },
  { path: '/v3/talent_analytics/talent_link_members', methods: mock28 },
  { path: '/v3/talent_analytics/synchronize', methods: mock29 },
  { path: '/v3/talent_analytics/relation', methods: mock30 },
  { path: '/v3/talent_analytics/relation/_id@number', methods: mock31 },
  { path: '/v3/talent_analytics/pdf', methods: mock32 },
  { path: '/v3/talent_analytics/link', methods: mock33 },
  { path: '/v3/talent_analytics/csv_talent_import', methods: mock34 },
  { path: '/v3/sms/members', methods: mock35 },
  { path: '/v3/sms/auth/personal', methods: mock36 },
  { path: '/v3/sms/auth/cancel', methods: mock37 },
  { path: '/v3/sms/auth/all', methods: mock38 },
  { path: '/v3/slack/team', methods: mock39 },
  { path: '/v3/slack/members', methods: mock40 },
  { path: '/v3/slack/auth/workspace_clear', methods: mock41 },
  { path: '/v3/slack/auth/personal', methods: mock42 },
  { path: '/v3/slack/auth/cancel', methods: mock43 },
  { path: '/v3/settings/security/register', methods: mock44 },
  { path: '/v3/settings/security/password_reset_email', methods: mock45 },
  { path: '/v3/settings/security/log', methods: mock46 },
  { path: '/v3/settings/security', methods: mock47 },
  { path: '/v3/settings/security/download_logs', methods: mock48 },
  { path: '/v3/settings/security/categories', methods: mock49 },
  { path: '/v3/seniors', methods: mock50 },
  { path: '/v3/seniors/_id@number', methods: mock51 },
  { path: '/v3/refresh-token', methods: mock52 },
  { path: '/v3/password_reset_execute', methods: mock53 },
  { path: '/v3/password_reset', methods: mock54 },
  { path: '/v3/notifications', methods: mock55 },
  { path: '/v3/notifications/_notificationId@number', methods: mock56 },
  { path: '/v3/notification_special', methods: mock57 },
  { path: '/v3/messages/templates', methods: mock58 },
  { path: '/v3/messages/templates/_code@string', methods: mock59 },
  { path: '/v3/messages/send', methods: mock60 },
  { path: '/v3/messages/master_templates', methods: mock61 },
  { path: '/v3/messages/master_templates/_code@string', methods: mock62 },
  { path: '/v3/messages', methods: mock63 },
  { path: '/v3/messages/_id@string', methods: mock64 },
  { path: '/v3/members/unread', methods: mock65 },
  { path: '/v3/members/read/_id@number', methods: mock66 },
  { path: '/v3/members/monthly', methods: mock67 },
  { path: '/v3/members', methods: mock68 },
  { path: '/v3/members/first_delivery', methods: mock69 },
  { path: '/v3/members/download', methods: mock70 },
  { path: '/v3/members/bounce_mail_count', methods: mock71 },
  { path: '/v3/members/anniversaries/count', methods: mock72 },
  { path: '/v3/members/anniv_answers_list', methods: mock73 },
  { path: '/v3/members/_id@number/test_mail', methods: mock74 },
  { path: '/v3/members/_id@number/mail_schedule', methods: mock75 },
  { path: '/v3/members/_id@number', methods: mock76 },
  { path: '/v3/members/_id@number/anniv_read', methods: mock77 },
  { path: '/v3/members/_id@number/anniv_answers', methods: mock78 },
  { path: '/v3/mailtests/welcome', methods: mock79 },
  { path: '/v3/mailtests/thanks', methods: mock80 },
  { path: '/v3/mailtests/senior-report', methods: mock81 },
  { path: '/v3/mailtests/monthly', methods: mock82 },
  { path: '/v3/mailtests/monthly-summary', methods: mock83 },
  { path: '/v3/mailtests/monthly-remind', methods: mock84 },
  { path: '/v3/mailtests/monthly-complete', methods: mock85 },
  { path: '/v3/mailtests/monthly-all', methods: mock86 },
  { path: '/v3/mailtests/annual', methods: mock87 },
  { path: '/v3/mailtests/annual-remind', methods: mock88 },
  { path: '/v3/mailtests/annual-old', methods: mock89 },
  { path: '/v3/mail_settings/save', methods: mock90 },
  { path: '/v3/mail_settings/preview', methods: mock91 },
  { path: '/v3/mail_settings', methods: mock92 },
  { path: '/v3/logout', methods: mock93 },
  { path: '/v3/login', methods: mock94 },
  { path: '/v3/line/members', methods: mock95 },
  { path: '/v3/line/auth/personal', methods: mock96 },
  { path: '/v3/line/auth/cancel', methods: mock97 },
  { path: '/v3/line/auth/all', methods: mock98 },
  { path: '/v3/ip', methods: mock99 },
  { path: '/v3/enqs', methods: mock100 },
  { path: '/v3/enqs/custom_monthly_questions', methods: mock101 },
  { path: '/v3/enqs/custom_monthly_questions/_id@number', methods: mock102 },
  { path: '/v3/enq_mail_settings/_enqMsgType@number', methods: mock103 },
  { path: '/v3/csv_update_import', methods: mock104 },
  { path: '/v3/csv_registration_import', methods: mock105 },
  { path: '/v3/company_services/link', methods: mock106 },
  { path: '/v3/company_services', methods: mock107 },
  { path: '/v3/company_counts', methods: mock108 },
  { path: '/v3/companies/settings/viewers/_id@number', methods: mock109 },
  { path: '/v3/companies/settings/viewers', methods: mock110 },
  { path: '/v3/companies/settings/senders', methods: mock111 },
  { path: '/v3/companies/settings/monthly_thanks', methods: mock112 },
  { path: '/v3/companies/settings/member_list_count', methods: mock113 },
  { path: '/v3/companies/settings/initial_mails', methods: mock114 },
  { path: '/v3/companies/pages', methods: mock115 },
  { path: '/v3/companies', methods: mock116 },
  { path: '/v3/companies/annual_notification', methods: mock117 },
  { path: '/v3/companies/annual_confirmation', methods: mock118 },
  { path: '/v3/avatars/_url@string', methods: mock119 },
  { path: '/v3/answers/xlsx', methods: mock120 },
  { path: '/v3/answers/report', methods: mock121 },
  { path: '/v3/answers/report/count', methods: mock122 },
  { path: '/v3/answers/report/condition-detail', methods: mock123 },
  { path: '/v3/answers/report/condition-count', methods: mock124 },
  { path: '/v3/answers/report/condition-calendar', methods: mock125 },
  { path: '/v3/answers/report/calendar', methods: mock126 },
  { path: '/v3/answers/chart', methods: mock127 },
  { path: '/v3/anniversaries/xlsx', methods: mock128 },
  { path: '/v3/anniversaries/xlsx-new', methods: mock129 },
  { path: '/v3/anniversaries/report', methods: mock130 },
  { path: '/v3/actionlogs', methods: mock131 },
  { path: '/v3/actionlogs/_id@number', methods: mock132 },
];

export default <U>(client: AspidaClient<U> | MockClient<U>, config?: MockConfig | undefined) => {
  const mock = 'attachRoutes' in client ? client : mockClient(client);
  mock.attachRoutes(mockRoutes(), config);

  return api(mock);
};
