import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  delete: {
    resBody: {
      message: string
    }
  }
}>

export default mockMethods<Methods>({
  delete: () => ({
    status: 200,
    resBody: {
      message: 'You have been successfully logged out',
    },
  }),
})
