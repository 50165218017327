import { format, getDay } from 'date-fns'

type UseDate = {
  dateFormat: (date: string, pattern?: string) => string
  weekday: (date: string) => string
}

export default function useDate(): UseDate {
  const dateFormat = (date: string, pattern: string = 'yyyy.MM.dd'): string => {
    const validDate = date ? new Date(date) : new Date()
    return isNaN(validDate.getTime()) ? '' : format(validDate, pattern)
  }

  const weekday = (date: string): string => {
    const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土']
    return daysOfWeek[getDay(new Date(date))]
  }

  return {
    dateFormat,
    weekday,
  }
}
