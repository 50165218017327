import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { AnniversaryAnswer } from '~/types/anniversary/answer'
import type {
  AnnivAnswersListResponse,
  Comments,
  AnnivAnswer,
} from '~/types/anniversary/answers'

export type Methods = DefineMethods<{
  get: {
    resBody: AnnivAnswersListResponse
    query?: {
      codes: string
    }
  }
}>

const annivAnswer: AnnivAnswer = {
  id: 1,
  lastname: 'エン',
  firstname: '太郎',
  anniv_sent: 1,
  anniv_answer_completed: 1,
  image_uri: null,
  department_id: null,
  date_contracted: '2020-01-01',
  department: {
    id: 1,
    name: null,
  },
}

const annivAnswers: AnniversaryAnswer[] = Array.from({ length: 34 + 8 }).map(
  (_, i) => ({
    id: i + 1,
    member_id: 1,
    company_id: 1,
    senior_id: i < 34 ? 0 : 1,
    question_index: i < 34 ? i : i - 34,
    answer: Math.floor(Math.random() * 6) as 0 | 1 | 2 | 3 | 4 | 5,
    job_category: 1,
    average: (2 + Math.ceil(Math.random() * 20) / 10).toFixed(1),
    company_average: (2 + Math.ceil(Math.random() * 20) / 10).toFixed(1),
    company_count: 1,
    created_at: '2020-01-01',
  }),
)

const comments: Comments = {
  motivation:
    '平均よりもやや高い回答となっています。現時点での離職リスクは低いですが、下記「活躍状況」が低いと将来の離職リスクにつながる可能性もありますので、併せてご確認ください。',
  success:
    'いずれの項目も平均より高く、活躍の実感を持ち順調に仕事に取り組めています。特に、仕事で周りに貢献できている実感を強く持っているようです。',
  senior:
    'いずれの項目も平均より高く、上司からの高い評価を得て順調に仕事に取り組めている様子がうかがえます。特に、仕事の成果や周囲への好影響について、上司の評価が高いようです。',
  matching:
    'イメージと現実のマッチングは、仕事の内容や進め方は入社前のイメージ通りであるものの、同僚の雰囲気やスキルレベルについてはあまりイメージ通りではないと回答しています。',
  support:
    '上司からのサポートは全体的に不足がちで、上司は本人の役割や目標を具体的に提示してくれていないようです。',
  workplace:
    '職場の雰囲気は全体的に良好で、同僚からのサポートが充実していると回答しています。',
  stress:
    '仕事のストレスは全体的に強く感じており、中でも業務量や残業が多いと回答しています。',
}

const annivAnswersListResponse: AnnivAnswersListResponse = [
  [
    annivAnswer,
    {
      anniv_answers: annivAnswers,
      comments,
    },
  ],
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: annivAnswersListResponse,
  }),
})
