import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import { startOfMonth, subMonths, format } from 'date-fns'
import type { Enq } from '~/types/enq'

export type Methods = DefineMethods<{
  get: {
    resBody: Res
  }
}>

type Res = {
  last_enq: Enq
  start_date: Date
  limit_day: number
}
const now = new Date()
const firstOfMonth = format(startOfMonth(now), 'yyyy-MM-dd')
const res = {
  last_enq: {
    id: 1,
    company_id: 1,
    date_sent: firstOfMonth,
    delivered_count: 1,
    created_at: firstOfMonth,
    updated_at: firstOfMonth,
  },
  start_date: subMonths(now, 11),
  limit_day: 10,
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: res,
  }),
})
