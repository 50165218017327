import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  put: {
    reqBody: {
      name?: string
      department?: string
    }

    resBody: {
      data: object
    }
  }
  delete: {
    resBody: {
      message: string
    }
  }
}>

export default mockMethods<Methods>({
  put: () => ({
    status: 200,
    resBody: {
      data: {
        status: 'success',
      },
    },
  }),
  delete: () => ({
    status: 200,
    resBody: {
      message: 'success',
    },
  }),
})
