<template>
  <img
    :src="src"
    :style="style"
    :alt="alt"
    class="icon"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  name: string
  width: string
  height: string
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  width: '24',
  height: '24',
})

const style = computed(() => ({
  '--width': `${props.width}px`,
  '--height': `${props.height}px`,
}))

/**
 * NOTE: ローカル開発サーバー, ビルド時, Storybook で必要な画像パスが異なるため、import.mega.glob を使用して出し分けている
 * https://vitejs.dev/guide/features#glob-import
 */
const sources = import.meta.glob('@/assets/img/icon/**/*', {
  import: 'default',
  eager: true,
})

const src = computed(() => {
  const _src = Object.keys(sources).find(s => s.indexOf(props.name) > 0) || ''
  return sources[_src] as string
})

const alt = `icon-${props.name}`
</script>

<style scoped>
.icon {
  width: var(--width);
  height: var(--height);
}
</style>
