import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  get: {
    responseType: 'blob',
    resBody: Blob
  }
}>

export default mockMethods<Methods>({
  get: () => ({
    responseType: 'blob',
    status: 200,
    resBody: new Blob(),
  }),
})
