import type { DefineMethods } from 'aspida'
import type { Code } from '~/types/member'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  post: {
    reqFormat: FormData
    reqBody: {
      lastname: string
      firstname: string
      lastname_kana?: string
      firstname_kana?: string
      employee_code?: string
      employing_type: number
      working_status: number
      date_resigned?: string
      resign_reason?: string
      resign_reason_text?: string
      gender: number
      birth_year: string
      birth_month: string
      birth_day: string
      mobile_tel_number?: string
      email: string
      date_contracted: string
      delivery_date: string
      department_id?: number
      profession?: string
      senior_id?: number
      initial_evaluation: number
      is_newbie: number
      entry_reason?: string
      recruiter?: string
      initial_evaluated_point?: string
      initial_worry_point?: string
      route_employed?: number
      route_employed_detail?: string
      job_change_count?: number
      working_years?: number
      graduation_type?: number
      ex_company?: string
      ex_profession?: string
      other_info?: string
      other_info2?: string
      other_info3?: string
      is_stopped_send_questions_mail?: number
      is_stopped_send_anniv_mail?: number
      mail_language: number
      avatar_file?: Blob
    },
    resBody: {
      result: Code
    }
  }
}>

const member = {
  id: 1,
  code: 5772441978,
  email: 'test@en-japan.com',
  lastname: '鈴木',
  firstname: '一郎',
  lastname_kana: 'スズキ',
  firstname_kana: 'イチロウ',
  company_id: 1,
  senior_id: 1,
  employee_code: '100001',
  date_contracted: '2017-11-01',
  date_resigned: '2020-09-17',
  resign_reason: ['7'],
  resign_reason_text: '転職のため',
  initial_evaluation: 1,
  has_image: 1 as boolean | 0 | 1,
  image_uri: '/img/mock/user1.png',
  condition_type: 1,
  progress_status: 1,
  working_status: 1,
  interview_status: 1,
  importance: 1,
  interview_due_date: '2020/12/31',
  gender: 1,
  age: 22,
  birth_year: 2010,
  birth_month: 10,
  birth_day: 2,
  profession: 'ディレクター',
  hr_user_id: 23,
  recruiter: '高橋花子',
  ex_company: 'テスト',
  ex_profession: 'ディレクター',
  entry_reason: 'がんばってた',
  other_info: 'テスト',
  other_info2: 'テスト',
  other_info3: 'テスト',
  date_quit: '',
  is_newbie: 1,
  is_deleted: 0 as 0 | 1,
  is_stopped_send_anniv_mail: 0,
  is_stopped_send_questions_mail: 0,
  created_at: '2020-09-17',
  updated_at: '2020-09-17',
  initial_evaluated_point: '1',
  initial_worry_point: '1',
  job_change_count: 5,
  working_years: 6,
  graduation_type: 1,
  employing_type: 1,
  using_slack: 1 as 0 | 1 | 2,
  slack_email: 'test@en-japan.com',
  slack_user_id: 1,
  bounce_count: 2,
  mobile_tel_number: '04077779999',
  sms_status: 2 as 0 | 1 | 2 | 3, 
  line_user_id: '',
  line_status: 2 as 0 | 1 | 2 | 3,
  talent_member_id: 1,
  talent_status: 1 as 0 | 1,
  line_works_id: 1,
  line_works_send_success_flag: 0,
  route_employed: 1,
  route_employed_detail: '',
  anniv_sent: 1,
  anniv_renewal_sent_date: '2020-01-31',
  anniv_answer_completed: 1,
  anniv_senior_answer_completed: 1,
  delivery_date: '2019-08-01',
  bounce_reason: '',
  app_member_token: '',
  push_enabled: 1 as boolean | 0 | 1,
  app_status: 2 as 0 | 1 | 2,
  mail_language: 0,
  has_answer: true,
  department: {
    id: 1,
    name: '東京営業所',
    full_path: '営業部 > 営業第二課 > 東京営業所',
  },
}

export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      result: member,
      message: "メンバー更新しました"
    },
  }),
})
