import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'

export type Methods = DefineMethods<{
  delete: {
    resBody: {
      success: boolean
    }
  }
  patch: {
    resBody: Res
  }
  get: {
    resBody: {
      link_status: number
    }
  }
}>

type Res = {
  success: boolean
  status: number
  response: string
  message: string
}

export default mockMethods<Methods>({
  delete: () => ({
    status: 200,
    resBody: {
      success: true,
    },
  }),
  patch: () => ({
    status: 200,
    resBody: {
      success: true,
      status: 200,
      response: '',
      message: '',
    },
  }),
  get: () => ({
    status: 200,
    resBody: {
      link_status: 1,
    },
  }),
})
