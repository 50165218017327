import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Message } from '~/types/message'

export type Methods = DefineMethods<{
  get: {
    resBody: Message[]
    query?: {
      code: string
    }
  }
}>

const messages = [
  {
    id: 1,
    user_id: 1,
    member_id: 1,
    company_id: 1,
    message_status: 1,
    subject: 'メッセージをご確認ください',
    sender: '68d30a9594728bc39aa24be94b319d21_stg@message.on-board.io',
    recipient: '9aa24be94b319d@en-japan.com',
    mail_body:
      'こんにちは、人事 太朗です。\n\n今月のアンケートにご回答いただきまして、ありがとうございました。\n\n回答いただいた内容から、日頃の業務に前向きに取り組んでいらっしゃる様子が伺えました。\n\nそれでは、引き続きよろしくお願いします。',
    created_at: '2020-09-17',
    updated_at: '2020-09-17',
    hr_user_name: '人事 太朗',
    user_department: '人事部',
    member_department: '総務部',
    member_name: '松本 神志',
    mailgun_message_id:
      '8c2dc65700512e128d3e213f1c70f652@onboard-new.localhost',
    bounce_status: 0,
    bounce_reason:
      'smtp;550 5.1.10 RESOLVER.ADR.RecipientNotFound; Recipient hrob.imanakaX@en-japan.com not found by SMTP address lookup',
    deleted_at: null,
  },
  {
    id: 2,
    user_id: 1,
    member_id: 1,
    company_id: 1,
    message_status: 2,
    subject: 'Re：メッセージをご確認ください',
    sender: '9aa24be94b319d@en-japan.com',
    recipient: '68d30a9594728bc39aa24be94b319d21_stg@message.on-board.io',
    mail_body:
      '人事 太朗さん\nエンです。\n\nメッセージありがとうございます。\n気に掛けていただき嬉しいです。\n\n引き続きよろしくお願いいたします。\n\nエン',
    created_at: '2020-09-20',
    updated_at: '2020-09-20',
    hr_user_name: '鈴木 一郎',
    user_department: '人事部',
    member_department: '営業部',
    member_name: 'エン 太郎',
    mailgun_message_id:
      '8c2dc65700512e12843e213f1c70f652@onboard-new.localhost',
    bounce_status: 0,
    bounce_reason: null,
    deleted_at: null,
  },
]

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: messages,
  }),
})
