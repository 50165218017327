import type { DefineMethods } from 'aspida'
import type {
  AnswerChartData,
  ConditionChartData,
  AnswerResponse,
  ConditionResponse,
  ConditionDetailResponse,
} from '~/types/chart'
import { mockMethods } from 'aspida-mock'
import { range } from 'lodash'
import { startOfMonth, subMonths, format } from 'date-fns'

export type Methods = DefineMethods<{
  get: {
    resBody: {
      pieAnswerChartBaseData: AnswerChartData
      pieConditionChartBaseData: ConditionChartData
      answerCalendarChartBaseData: AnswerResponse
      answerMonthlyChartBaseData: AnswerResponse
      conditionCalendarChartBaseData: ConditionResponse
      conditionMonthlyChartBaseData: ConditionResponse
      conditionDetailChartBaseData: ConditionDetailResponse
      monthlyData: number[]
      firstDeliveryDate: string
    }
  }
}>

const pieAnswerChartBaseData = {
  answer_num: 11,
  not_answer_num: 4,
  current_rate: 73,
}

const pieConditionChartBaseData = { sunny_num: 5, cloudy_num: 4, rainy_num: 2 }

export const answerCalendarChartBaseData = {
  '2020/01': {
    answer_num: 5,
    answer_rate: 33,
    delivery_num: 15,
  },
  '2020/02': {
    answer_num: 5,
    answer_rate: 33,
    delivery_num: 15,
  },
  '2020/03': {
    answer_num: 3,
    answer_rate: 20,
    delivery_num: 15,
  },
  '2020/04': {
    answer_num: 5,
    answer_rate: 33,
    delivery_num: 15,
  },
  '2020/05': {
    answer_num: 6,
    answer_rate: 40,
    delivery_num: 15,
  },
  '2020/06': {
    answer_num: 9,
    answer_rate: 60,
    delivery_num: 15,
  },
  '2020/07': {
    answer_num: 9,
    answer_rate: 60,
    delivery_num: 15,
  },
  '2020/08': {
    answer_num: 10,
    answer_rate: 66,
    delivery_num: 15,
  },
  '2020/09': {
    answer_num: 10,
    answer_rate: 66,
    delivery_num: 15,
  },
  '2020/10': {
    answer_num: 12,
    answer_rate: 80,
    delivery_num: 15,
  },
  '2020/11': {
    answer_num: 12,
    answer_rate: 80,
    delivery_num: 15,
  },
  '2020/12': {
    answer_num: 12,
    answer_rate: 80,
    delivery_num: 15,
  },
}

export const answerMonthlyChartBaseData = {
  '1回目': {
    answer_num: 5,
    answer_rate: 33,
    delivery_num: 15,
  },
  '2回目': {
    answer_num: 5,
    answer_rate: 33,
    delivery_num: 15,
  },
  '3回目': {
    answer_num: 3,
    answer_rate: 20,
    delivery_num: 15,
  },
  '4回目': {
    answer_num: 5,
    answer_rate: 33,
    delivery_num: 15,
  },
  '5回目': {
    answer_num: 6,
    answer_rate: 40,
    delivery_num: 15,
  },
  '6回目': {
    answer_num: 9,
    answer_rate: 60,
    delivery_num: 15,
  },
  '7回目': {
    answer_num: 9,
    answer_rate: 60,
    delivery_num: 15,
  },
  '8回目': {
    answer_num: 10,
    answer_rate: 66,
    delivery_num: 15,
  },
  '9回目': {
    answer_num: 10,
    answer_rate: 66,
    delivery_num: 15,
  },
  '10回目': {
    answer_num: 12,
    answer_rate: 80,
    delivery_num: 15,
  },
  '11回目': {
    answer_num: 12,
    answer_rate: 80,
    delivery_num: 15,
  },
  '12回目': {
    answer_num: 12,
    answer_rate: 80,
    delivery_num: 15,
  },
}

export const conditionCalendarChartBaseData = {
  '2020/01': {
    sunny_num: 3,
    sunny_rate: 60,
    cloudy_num: 1,
    cloudy_rate: 20,
    rainy_num: 1,
    rainy_rate: 20,
  },
  '2020/02': {
    sunny_num: 3,
    sunny_rate: 60,
    cloudy_num: 1,
    cloudy_rate: 20,
    rainy_num: 1,
    rainy_rate: 20,
  },
  '2020/03': {
    sunny_num: 2,
    sunny_rate: 67,
    cloudy_num: 1,
    cloudy_rate: 33,
    rainy_num: 0,
    rainy_rate: 0,
  },
  '2020/04': {
    sunny_num: 2,
    sunny_rate: 40,
    cloudy_num: 2,
    cloudy_rate: 40,
    rainy_num: 1,
    rainy_rate: 20,
  },
  '2020/05': {
    sunny_num: 3,
    sunny_rate: 50,
    cloudy_num: 2,
    cloudy_rate: 33,
    rainy_num: 1,
    rainy_rate: 17,
  },
  '2020/06': {
    sunny_num: 5,
    sunny_rate: 56,
    cloudy_num: 2,
    cloudy_rate: 22,
    rainy_num: 2,
    rainy_rate: 22,
  },
  '2020/07': {
    sunny_num: 5,
    sunny_rate: 56,
    cloudy_num: 2,
    cloudy_rate: 22,
    rainy_num: 2,
    rainy_rate: 22,
  },
  '2020/08': {
    sunny_num: 6,
    sunny_rate: 60,
    cloudy_num: 2,
    cloudy_rate: 20,
    rainy_num: 2,
    rainy_rate: 20,
  },
  '2020/09': {
    sunny_num: 7,
    sunny_rate: 70,
    cloudy_num: 1,
    cloudy_rate: 10,
    rainy_num: 2,
    rainy_rate: 20,
  },
  '2020/10': {
    sunny_num: 8,
    sunny_rate: 67,
    cloudy_num: 2,
    cloudy_rate: 17,
    rainy_num: 2,
    rainy_rate: 17,
  },
  '2020/11': {
    sunny_num: 9,
    sunny_rate: 75,
    cloudy_num: 2,
    cloudy_rate: 17,
    rainy_num: 1,
    rainy_rate: 8,
  },
  '2020/12': {
    sunny_num: 10,
    sunny_rate: 83,
    cloudy_num: 2,
    cloudy_rate: 17,
    rainy_num: 0,
    rainy_rate: 0,
  },
}

export const conditionMonthlyChartBaseData = {
  '1回目': {
    sunny_num: 3,
    sunny_rate: 60,
    cloudy_num: 1,
    cloudy_rate: 20,
    rainy_num: 1,
    rainy_rate: 20,
  },
  '2回目': {
    sunny_num: 3,
    sunny_rate: 60,
    cloudy_num: 1,
    cloudy_rate: 20,
    rainy_num: 1,
    rainy_rate: 20,
  },
  '3回目': {
    sunny_num: 2,
    sunny_rate: 67,
    cloudy_num: 1,
    cloudy_rate: 33,
    rainy_num: 0,
    rainy_rate: 0,
  },
  '4回目': {
    sunny_num: 2,
    sunny_rate: 40,
    cloudy_num: 2,
    cloudy_rate: 40,
    rainy_num: 1,
    rainy_rate: 20,
  },
  '5回目': {
    sunny_num: 3,
    sunny_rate: 50,
    cloudy_num: 2,
    cloudy_rate: 33,
    rainy_num: 1,
    rainy_rate: 17,
  },
  '6回目': {
    sunny_num: 5,
    sunny_rate: 56,
    cloudy_num: 2,
    cloudy_rate: 22,
    rainy_num: 2,
    rainy_rate: 22,
  },
  '7回目': {
    sunny_num: 5,
    sunny_rate: 56,
    cloudy_num: 2,
    cloudy_rate: 22,
    rainy_num: 2,
    rainy_rate: 22,
  },
  '8回目': {
    sunny_num: 6,
    sunny_rate: 60,
    cloudy_num: 2,
    cloudy_rate: 20,
    rainy_num: 2,
    rainy_rate: 20,
  },
  '9回目': {
    sunny_num: 7,
    sunny_rate: 70,
    cloudy_num: 1,
    cloudy_rate: 10,
    rainy_num: 2,
    rainy_rate: 20,
  },
  '10回目': {
    sunny_num: 8,
    sunny_rate: 67,
    cloudy_num: 2,
    cloudy_rate: 17,
    rainy_num: 2,
    rainy_rate: 17,
  },
  '11回目': {
    sunny_num: 9,
    sunny_rate: 75,
    cloudy_num: 2,
    cloudy_rate: 17,
    rainy_num: 1,
    rainy_rate: 8,
  },
  '12回目': {
    sunny_num: 10,
    sunny_rate: 83,
    cloudy_num: 2,
    cloudy_rate: 17,
    rainy_num: 0,
    rainy_rate: 0,
  },
}

export const conditionDetailChartBaseData = {
  1: {
    so_good: {
      count: 10,
      percentage: 83,
      label: 'イメージ通り！',
    },
    good: {
      count: 2,
      percentage: 17,
      label: 'ややイメージ通り',
    },
    normal: {
      count: 0,
      percentage: 0,
      label: 'どちらでもない',
    },
    bad: {
      count: 0,
      percentage: 0,
      label: 'やや違う',
    },
    so_bad: {
      count: 0,
      percentage: 0,
      label: '全く違う…',
    },
    question: '仕事内容は入社前のイメージと比べていかがですか？',
    total: 12,
  },
  2: {
    so_good: {
      count: 8,
      percentage: 67,
      label: '慣れた！',
    },
    good: {
      count: 1,
      percentage: 8,
      label: 'やや慣れた',
    },
    normal: {
      count: 1,
      percentage: 8,
      label: 'どちらでもない',
    },
    bad: {
      count: 2,
      percentage: 17,
      label: 'あまり慣れてない',
    },
    so_bad: {
      count: 0,
      percentage: 0,
      label: '慣れてない…',
    },
    question: '職場の雰囲気には慣れましたか？',
    total: 12,
  },
  3: {
    so_good: {
      count: 4,
      percentage: 33,
      label: '話しやすい',
    },
    good: {
      count: 4,
      percentage: 33,
      label: 'やや話しやすい',
    },
    normal: {
      count: 2,
      percentage: 17,
      label: 'どちらでもない',
    },
    bad: {
      count: 1,
      percentage: 8,
      label: '少し話しづらい',
    },
    so_bad: {
      count: 1,
      percentage: 8,
      label: '話しづらい…',
    },
    question:
      'この1ヶ月、仕事の相談や報告をする時に直属の上司は話しやすかったですか？',
    total: 12,
  },
}
const monthlyData = range(12).map(i => i + 1)

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: {
      pieAnswerChartBaseData,
      pieConditionChartBaseData,
      answerCalendarChartBaseData,
      answerMonthlyChartBaseData,
      conditionCalendarChartBaseData,
      conditionMonthlyChartBaseData,
      conditionDetailChartBaseData,
      monthlyData,
      firstDeliveryDate: format(
        subMonths(startOfMonth(new Date()), 12),
        'Y-MM-dd',
      ),
    },
  }),
})
