import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { TalentUnlinkMember } from '~/types/talentMember'

export type Methods = DefineMethods<{
  get: {
    query?: {
      limit: number
      offset: number
    }
    resBody: {
      members: TalentUnlinkMember[]
      count: number
    }
  }
}>

const talent_unlink_members = {
  count: 1,
  members: [
    {
      id: 176,
      last_name: 'テスト',
      first_name: '太郎',
      email: 'talent_unlink_members@en-japan.com',
    },
    {
      id: 177,
      last_name: 'テスト',
      first_name: '次郎',
      email: 'talent_unlink_members02@en-japan.com',
    },
    {
      id: 178,
      last_name: 'テスト',
      first_name: '三郎',
      email: 'talent_unlink_members03@en-japan.com',
    },
  ],
}

export default mockMethods<Methods>({
  get: () => ({
    status: 200,
    resBody: talent_unlink_members,
  }),
})
