import type { DefineMethods } from 'aspida'
import { mockMethods } from 'aspida-mock'
import type { Feedback } from '~/types/feedback'

export type Methods = DefineMethods<{
  post: {
    reqBody: Feedback,
    resBody: { message: string }
  }
}>


export default mockMethods<Methods>({
  post: () => ({
    status: 200,
    resBody: {
      message: 'フィードバックを送信しました',
    },
  }),
})